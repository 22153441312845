import React from 'react';
import {Typography, Grid, Dialog, DialogTitle, DialogContent, Button, DialogActions} from '@material-ui/core';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { DEFAULT_LANGUAGE } from '../constants/languages';
import linkIcon from './icons/externallinkIcon.svg';


export default function ActionModal({action, labels, open, onClose, color, signed_user, programs, language}) {
    const programColor = programs.find(p => p.originalId === action.programId)?.color || '#fff';
    const dates = action.validation_period.split("-") || ["-", "-"]
    const classes = makeStyles(theme => ({
        dates: {
            color: '#787778',
            fontSize: '13px'
        },
        datesTitle: {
            fontSize: 12,
            color: theme.palette.primary.main,
        },
        datesTable: {
            borderCollapse: "collapse",
            width: "100%"
        },
        datesCells: {
            border: "1px solid",
            borderColor: theme.palette.primary.main,
            padding: 10,
            fontSize: 10,
        },
        program: {
            backgroundColor: color,
            padding: '3px 10px',
            marginBottom: 10,
            color: theme.palette.getContrastText(color),
            fontSize: 14,
            width: "max-content"
        },
        name: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: 25
        },
        divider: {
            margin: 20
        },
        subtitle: {
            color: theme.palette.primary.main
        },
        section:{
            padding: "15px 0px 15px 0px"
        },
        list: {
            paddingTop: 0
        },
        listItem: {
            paddingLeft: 0
        },
        entity: {
            color: '#787778'
        },
        teachingArea: {
            backgroundColor: programColor, 
            color: "white",
            padding: '10px',
            marginBottom: 30,
            fontSize: 12
        },
        tableHeader: {
            backgroundColor: theme.palette.header,
            color: "white",
            border: "1px solid",
            borderColor: theme.palette.evenRow
        },
        tableRow: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.evenRow,
              }
        },
        tableCell: {
            border: "1px solid",
            borderColor: theme.palette.evenRow
        },
        brochure_button: {
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            borderRadius: 0,
            textTransform: 'Capitalize',
        }
    }))();
    const partnersTable = (labels, partnersDetail, language) => {
        return (
        <Table sx={{ minWidth: 650 }}>
            <TableHead >
                <TableRow>
                    <TableCell className={classes.tableHeader}>{labels.country}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.region}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.university}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.faculty}</TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {partnersDetail.map((row, index) => (
                        <TableRow key={`${index}_partner`} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{row.zone.zone_hierarchy[0][`${language}_name`]}</TableCell>
                            <TableCell className={classes.tableCell}>{row.zone[`${language}_name`] || row.zone[`${DEFAULT_LANGUAGE}_name`]}</TableCell>
                            <TableCell className={classes.tableCell}>{(row[`${language}_parent_name`] || row[`${language}_name`]) || (row[`${DEFAULT_LANGUAGE}_parent_name`] || row[`${DEFAULT_LANGUAGE}_name`])}</TableCell>
                            <TableCell className={classes.tableCell}>{row[`${language}_parent_name`] ? row[`${language}_name`] : (row[`${DEFAULT_LANGUAGE}_parent_name`] ? row[`${DEFAULT_LANGUAGE}_name`] : "")}</TableCell>
                        </TableRow>
                    ))}
               </TableBody>
        </Table>    
        )
    } 
    const diplomasTable = (labels, action) => {
        const diplomaTitles = action.diploma_title.split(";")
        return (
        <Table sx={{ minWidth: 650 }}>
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    <TableCell className={classes.tableHeader}>{labels.diplomaTitle}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.languageInstruction}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.applicationPeriod}</TableCell>
                    <TableCell className={classes.tableHeader}>{labels.admissionLevel}</TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {diplomaTitles.map((row, index) => (
                        <TableRow key={`${index}_title`} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{row}</TableCell>
                            <TableCell className={classes.tableCell}>{action.language_details || action.instruction_language}</TableCell>
                            <TableCell className={classes.tableCell}>{action.application_period}</TableCell>
                            <TableCell className={classes.tableCell}>{action.admission_level}</TableCell>
                        </TableRow>
                    ))}
               </TableBody>
        </Table>    
        )
    }
    const isValidlink = action?.link_brochure.split("http").length - 1 === 1;
    return (
        <Dialog open={open} onClose={onClose} scroll='paper' maxWidth='lg' fullWidth>
            <DialogTitle>
                <Grid container justifyContent="flex-start">
                  <Grid item className={classes.teachingArea}>
                        {labels.teachingArea}: {action.program}
                  </Grid>
                </Grid>
                <Grid container justifyContent='space-between'>
                    <Grid item className={classes.name}>
                        {action.name}:
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={4} className={classes.datesTitle}>
                                {labels.validityPeriod}
                            </Grid>
                            <Grid item xs={8}>
                                <table className={classes.datesTable}>
                                    <tbody>
                                        <tr>
                                            <td className={classes.datesCells}>{labels.debut}: {dates[0]}</td>
                                            <td className={classes.datesCells}>{labels.fin}: {dates[1]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>   
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid item className={classes.section}>
                    <Typography variant="body2">{action.description}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="body1" className={classes.subtitle}>
                        {labels.partners}
                    </Typography>
                    {partnersTable(labels, action.partnersDetail, language)}
                </Grid>

                <Grid item xs={12} className={classes.section}>
                    <Typography variant="body1" className={classes.subtitle}>
                        {labels.diplomas}
                    </Typography>
                    {diplomasTable(labels, action, language)}
                </Grid>

            </DialogContent>
            <DialogActions>
                {
                    action.link_brochure &&
                    <Button autoFocus disabled={!isValidlink} href={isValidlink ? action.link_brochure : null} target="_blank" className={classes.brochure_button}>
                        {labels.brochure_button}
                        <img src={linkIcon} alt="icon" style={{width: 20, marginLeft: '8px'}}/>
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}