import {SET_SEARCH_VALUE} from '../actions/searcher.actionTypes.js';

export const initialState = {
    search_values: []
};

export const searcherReducer = (state = initialState, action = {}) => {
    if (action.type === SET_SEARCH_VALUE) {
        return {
            ...state,
            search_values: action.search_values
        };
    }
    else {
        return state;
    }
};
