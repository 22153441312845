import { Button } from "@material-ui/core";
import React from "react";
import {withStyles} from '@material-ui/core';
import moment from 'moment';
import html2canvas from 'html2canvas';
import iconScreenshot from './icons/IconScreenshot.svg';
import {getLabels} from '../constants/labels';

const styles = theme => ({
    colorButton: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontWeight: 'bold',
        borderRadius: 0,
        textTransform: 'Capitalize',
        position: 'absolute',
        right: '20px',
        padding: '11px 13px 11px 0',
        bottom: '24px',
        '&:hover': {
            backgroundColor: '#448E6D',
            color: 'white'
        }
    },
    title: {
        marginRight: '5px'
    }
});

function TakeScreenshot (props) {
    const toDay = moment().format("YYYY-MM-DD");
    const IconScreenshotComponent = () => <img src={iconScreenshot} alt="icon" style={{width: 30, marginLeft: 17}}/>

    const handleImageDownload = async () => {
        const element = document.getElementById('div-svg');
        const canvas = await html2canvas(element, { useCORS: true });
        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');
     
        link.href = data;
        link.download = `jamiati-wheel-sreenshot-${toDay}.jpg`;
     
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const labels = getLabels(props.language);
    return (
        <div>
            <Button
                size="small"
                variant="contained" startIcon={<IconScreenshotComponent />}
                className={props.classes.colorButton}
                onClick={handleImageDownload}
            >
                <span className={props.classes.title}>{labels.screenshot_button}</span>
            </Button>
        </div>
    );
};

export default withStyles(styles)(TakeScreenshot);