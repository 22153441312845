import { SET_SEARCH_VALUE } from './searcher.actionTypes.js';

function setSearchValues(values) {
    return {
        type: SET_SEARCH_VALUE,
        search_values: values
    }
}

export const searcherActions = {
    setSearchValues
}