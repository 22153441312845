export const language_instruction_default_values = [
    {
        id: "",
        label: "Langue(s) d'enseignement",
        label_fr: "Langue(s) d'enseignement",
        label_en: "Language(s) of instruction",
        checked: true
    },
    {
        id: "french",
        label: "Français",
        label_fr: "Français",
        label_en: "French",
        checked: true
    },
    {
        id: "english",
        label: "Anglais",
        label_fr: "Anglais",
        label_en: "English",
        checked: true
    }
]