import React from 'react';
import '../LogoCien.css';

class Logo extends React.Component {

    render() {
        return (<svg xmlns="http://www.w3.org/2000/svg" className="svg-logo" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="590 0 145 90">
            <g id="logo">
                <path className="cls-3" d="M608.13,47.84h0a7,7,0,0,1-1.51.16,8,8,0,0,1,0-16A7.34,7.34,0,0,1,611,33.46h0C604.91,31,597.45,43.1,608.13,47.84Z"/>
                <path className="cls-13" d="M600.67,52a13,13,0,1,1,17.85,4.35A13,13,0,0,1,600.67,52Zm16.82,5a14.8,14.8,0,1,0-20.34-5A14.8,14.8,0,0,0,617.49,57Z"/>
                <path className="cls-14" d="M605,56a11.14,11.14,0,1,1,11.13-11.14A11.14,11.14,0,0,1,605,56Zm11.13-10.1a12.69,12.69,0,1,0-12.69,12.69A12.69,12.69,0,0,0,616.14,45.88Z"/>
                <path className="cls-15" d="M639.57,36.82a11.43,11.43,0,0,0-6.36-2.88V31a13.5,13.5,0,0,1,6.34,2Z"/>
                <path className="cls-15" d="M633.21,55a11.43,11.43,0,0,0,6.35-2.88v3.75a13.41,13.41,0,0,1-6.35,2Z"/>
                <path className="cls-15" d="M631.1,57.83a13.49,13.49,0,0,1-12.33-13.39A13.55,13.55,0,0,1,631.1,31.05V34a10,10,0,0,0-6,3,10.71,10.71,0,0,0,0,15,10,10,0,0,0,6,3Z"/>
                <path className="cls-15" d="M648.87,31.31V57.6h-3.3V31.31Z"/>
                <path className="cls-15" d="M660.71,34.18h-4.26V43h4.26v2.87h-4.26v8.84h4.26V57.6h-7.56V31.31h7.56Zm2.11-2.87h4.63v2.87h-4.63Zm0,11.71h4.63v2.87h-4.63Zm0,11.71h4.63V57.6h-4.63Z"/>
                <path className="cls-15" d="M676.92,43a3.76,3.76,0,0,0-1.41.87,4.16,4.16,0,0,0-.9,1.23V57.6h-3.3V40.9h3.3a3.56,3.56,0,0,1,2.27-.86ZM679,40a6.62,6.62,0,0,1,6.11,6.62v11h-3.29V46.8A3.77,3.77,0,0,0,680.71,44,3.89,3.89,0,0,0,679,43Z"/>
                <path className="cls-15" d="M639.56,61.42A17.8,17.8,0,0,1,633.21,63V60.9a15.78,15.78,0,0,0,6.31-1.77Z"/>
                <path className="cls-15" d="M631.1,28.1a16.44,16.44,0,0,0-15.61,16.4,16.44,16.44,0,0,0,15.6,16.43V63a18.47,18.47,0,0,1-17.68-18.5,17.85,17.85,0,0,1,5.41-13.08A17.73,17.73,0,0,1,631.1,26Z"/>
                <path className="cls-15" d="M639.56,29.91a15.94,15.94,0,0,0-6.35-1.79V26a17.8,17.8,0,0,1,6.35,1.56Z"/>
            </g>
        </svg>);
    }
}

export default Logo;