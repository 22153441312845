import { SET_LANGUAGE } from './language.actionTypes';

function saveLanguage(language) {
    return {
        type: SET_LANGUAGE,
        language
    }
}

export const languageActions = {
    saveLanguage
}