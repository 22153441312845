import React from 'react';
import ActionCard from './ActionCard';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {getLabels} from '../constants/labels';

const useStyles = makeStyles({
    topLabel: {
        paddingBottom: '5px',
        paddingLeft: '30px',
        paddingTop: '60px'
    },
    root: {
        maxHeight: 950,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    container: {
        padding: '0px 30px 15px 30px'
    }
});

export function ActionsDisplay({actions, language, programs, signed_user}) {
    const classes = useStyles();
    const labels = getLabels(language);

    // Sorting accord by its number
    actions.sort((a1, a2) => {
        const a1_num = parseInt(a1.name.split("°")[1].trim())
        const a2_num = parseInt(a2.name.split("°")[1].trim())
        if (a1_num < a2_num) return -1
        else return 1
    });

    return (<>
        <div className={classes.topLabel}>
            <Typography component="span" variant="body2">
                <b>{labels.accords} ({actions.length})</b>
            </Typography>
        </div>
        <div className={classes.root}>
            <Grid container spacing={3} className={classes.container}>
                {actions.map((a, index) => (
                    <ActionCard key={index}
                        action={a}
                        language={language}
                        programs={programs}
                        signed_user={signed_user}
                    />
                ))}
            </Grid>
        </div>
    </>);
}
