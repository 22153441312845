import React, { PureComponent } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import SearcherContainer from '../../containers/SearcherContainer';
import {states} from './loginConstants';
import {connect} from 'react-redux';
import {searcherActions} from '../../actions/searcher.actionCreators';
import {languageActions} from '../../actions/language.actionCreators';
import {bindActionCreators} from 'redux';
import { DEFAULT_LANGUAGE, ENGLISH } from '../../constants/languages';
import languageIcon from '../icons/languageIcon.svg';
import returnIcon from '../icons/returnIcon.svg';
import logoJamiati from '../../logos-jamiati.svg';
import {theme} from "../../theme";
import {getLabels} from '../../constants/labels';
import logoJamiatiWhite from '../../logos-jamiati-white.svg';
import logoAmbaFr from '../../logos-ambafrance.svg';
import logoIFMaroc from '../../logos-if-maroc.png';
import logoCampusFr from '../../logos-campusfrance.svg';
import { EMBASSY_LINK, IF_LINK, CAMPUSFRANCE_LINK } from '../../constants/externalLinks';


const styles = theme => ({
    footer: {
        backgroundColor: theme.palette.primary,
        padding: '0px 20px 0px 20px',
        width: '100%',
    },
    subfooter: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '2px',
        height: '200px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        boxSizing: 'border-box',
    },
    vLine: {
        height: '110px',
        borderLeft: '2px solid white',
        marginTop: '40px',
    },
});

class Authentication extends PureComponent {
    state = {
        /*username: "",
        email: "",
        password: "",
        user: null,
        code: "", // Used for email verification*/
        status: states.PUBLIC,
        language: this.props.language || DEFAULT_LANGUAGE, // language is a redux stored prop
        /*oldPassword: "",
        newPassword: "",
        contact: ""*/
    }

    componentDidMount() {
        /*Auth.currentAuthenticatedUser()
            .then(data => {
                let user = {username: data.username, ...data.attributes}
                this.setState({user, status: this.state.status === states.PUBLIC ? states.WELCOME : this.state.status});
            })
            .catch(err => console.log(err));
        this.setState({contact: ""});*/
    }

    setLanguage(language) {
        const redux_actions = this.props.actions;
        if (language !== this.state.language) {
            this.setState({language},
                () => {
                    redux_actions.saveLanguage(language);
                });
        }
    }

    AuthComponent = () => {
        const { appComponent, notifySearchEvent, language, classes } = this.props;
        const labels = getLabels(language);
        const externalLink = (link, comp) => <a href={link} target="_blank" rel="noopener noreferrer" style={{cursor: "pointer", color: theme.palette.primary.main}}>{comp}</a>

        // Adjustment logo margin
        let logoMarginBottom = 0
        if (navigator.userAgent.indexOf("Firefox") !== -1) {
            logoMarginBottom = "35px"
        }

        switch (this.state.status) {
            default: // states.PUBLIC
                const new_language = language === DEFAULT_LANGUAGE ? ENGLISH : DEFAULT_LANGUAGE
                return (<div>
                    <Grid container alignItems="top" style={{padding: 20, fontSize: 11, height: "auto"}}>
                        <Grid item xs={3} container direction="column" style={{paddingLeft: 20, marginTop: 10}}>
                            <Grid item container style={{color: theme.palette.primary.main}}>
                                <Grid item xs={1}>
                                    {externalLink(EMBASSY_LINK, <img src={returnIcon} alt="icon" style={{width: 20}}/>)}
                                </Grid>
                                <Grid item xs={11} style={{textDecoration: "underline"}}>
                                    {externalLink(EMBASSY_LINK, labels.backToEmbassy)}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <img src={logoJamiati} alt="icon" style={{width: "80%", maxWidth: 200, paddingTop: 20, marginBottom: logoMarginBottom}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} style={{flexGrow: 1}}>
                            <SearcherContainer
                                signed_user={this.state.status === states.WELCOME}
                                notifySearchEvent={notifySearchEvent}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={1} container justifyContent="flex-end" style={{textAlign: "right", color: theme.palette.primary.main, paddingRight: 20}}>
                            <Grid item xs={6}>
                                <img src={languageIcon} alt="icon" style={{width: 20, cursor: "pointer"}} onClick={() => this.setLanguage(new_language)}/>
                            </Grid>
                            <Grid item xs={6} onClick={() => this.setLanguage(new_language)} style={{textDecoration: "underline", flexBasis: 0, marginLeft: 5, cursor: "pointer"}}>
                                {new_language}
                            </Grid>
                        </Grid>
                    </Grid>    
                    {appComponent}
                    {/* footer */}
                    <Grid container alignItems="center" className={classes.footer}>
                        <Grid container alignItems="center" className={classes.subfooter}>
                            <Grid item>
                                <img src={logoJamiatiWhite} alt="icon" style={{width: 250, paddingTop: 63, paddingLeft: 30}}/>
                            </Grid>
                            {/* Space for the text beside Jamiati logo */}
                            {/* <Grid item xs={11} style={{textDecoration: "underline"}}><span style={{cursor: "pointer"}}>{labels.backToEmbassy}</span></Grid> */}
                            <Grid item>
                                <div className={classes.vLine}></div>
                            </Grid>
                            <Grid item>
                                {externalLink(EMBASSY_LINK, <img src={logoAmbaFr} alt='icon' style={{width: 155, paddingTop: 65, paddingLeft: 75}} />)}
                            </Grid>
                            <Grid>
                                {externalLink(IF_LINK, <img src={logoIFMaroc} alt='icon' style={{width: 130, paddingTop: 50, paddingLeft: 50}} />)}
                            </Grid>
                            <Grid>
                                {externalLink(CAMPUSFRANCE_LINK, <img src={logoCampusFr} alt='icon' style={{width: 83, paddingTop: 49}} />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>);
        }
    }

    render() {
        return <>
        {this.AuthComponent()}
        </>;
    }
}

const mapStateToProps = state => {
    return {
        language: state.languageReducer.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({...searcherActions, ...languageActions}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Authentication));