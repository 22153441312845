import React from 'react';
import {withStyles} from '@material-ui/core';
import HomePage from './containers/HomePage';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import Logo from './components/Logo';
import Authentication from './components/login/Authentication';

const StyledLogo = withStyles((theme) => ({
    logo: {
        width: 300,
        paddingLeft: 70
    }
}))((props) => {
    return (<div className={props.classes.logo}><Logo/></div>);
});

export default class App extends React.Component {

  state = {
    search_event: false
  }

  notifySearchEvent() {
    this.setState(state => ({search_event: !state.search_event}));
  }

  render() {
    const { search_event } = this.state;
    return (
      <ThemeProvider theme={theme}>
            <Authentication
                appComponent={<HomePage search_event={search_event}/>}
                logo={<StyledLogo/>}
                notifySearchEvent={() => this.notifySearchEvent()}
            />
      </ThemeProvider>
    );
  }
}
