import React from 'react';
import {Grid, withStyles} from '@material-ui/core';
import visualizationDataService from '../services/visualizationService';
import VisualizationCanvas from '../components/VisualizationCanvas';
import {OTHER_COLOR} from '../constants/colors';
import {language_instruction_default_values} from '../constants/instructionLanguages';
import {level_diploma_default_values} from '../constants/diplomaLevels';
import {teaching_area_default_values} from '../constants/teachingAreas';
import slugify from 'slugify';
import {connect} from 'react-redux';
import {languageActions} from '../actions/language.actionCreators';
import {legendActions} from '../actions/legend.actionCreators';
import {bindActionCreators} from 'redux';
import {ALL_CATEGORIES} from '../constants/categories';
import {DEFAULT_LANGUAGE, ENGLISH} from '../constants/languages';


const OTHER_LINK_TYPE = 'other';

const styles = theme => ({
    root: {
        padding: '0px 20px 0px 20px'
    },
    activeButton: {
        textDecoration: 'underline'
    },
    tabs: {
        paddingLeft: "6px",
    },
    tab: {
        marginTop: '10px',
        color: 'rgba(0,0,0,0.6)',
        display: 'inline-block',
        padding: '10px',
        borderBottom: '3px solid rgba(0,0,0,0.2)',
        borderRadius: '10px 10px 0 0',
        cursor: 'pointer',
        backgroundColor: '#eeeeee',
        '&:hover, &:active': {
            backgroundColor: '#eceff1',
            borderBottom: '1px solid rgba(0,0,0,0.5)'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    },
    languages: {
        position: 'relative',
    },
});

export function getEntityRegion(entity) {
    const zone = entity.zone;
    if (!zone) return null;
    if (zone.type === 'region') return entity.zone;
    let country_zone = {};
    (zone.zone_hierarchy || []).forEach(t => {
        if (t.type === 'region') {
            country_zone = t;
        }
    });
    return country_zone || entity.zone;
}

export function getEntityCountry(entity) {        
    if (entity.zone_hierarchy?.[0] && entity.zone_hierarchy[0].type === 'pais') {
        return entity.zone_hierarchy[0];  
    }
    const zone = entity.zone;
    if (!zone) return null;
    if (!zone.zone_hierarchy) return null;    
    if (zone.zone_hierarchy[0].type === 'pais') return entity.zone.zone_hierarchy[0];   
    return null;
}

// Get categories structure for visualization
export function getCategories(entities_data, language=DEFAULT_LANGUAGE) {
    const nameKey = `fr_name`; //${language}
    let categories = [];
    entities_data.forEach(entity => {
        const region = getEntityRegion(entity);
        const country = getEntityCountry(entity);
        if (region && !categories.find(c => c.id === `${country.natural_name}.${region.natural_name}`)) {
            const category = {id: `${country.natural_name}.${region.natural_name}`, fr_name: region[nameKey], short_name: region.short_name, zone_hierarchy: region.zone_hierarchy};
            category['color'] = OTHER_COLOR;
            categories.push(category);
        }
        if (country && !categories.find(c => c.id === country.natural_name)) {
            const category = {id: country.natural_name, fr_name: country[nameKey], short_name: country.short_name};
            categories.push(category);
        }
    });
    return categories;
}

// Remove unused categories (Categories without children)
export function filterUsedCategories(categories, entities) {
    return categories.filter(c => {
        return entities.find(e => e?.id?.startsWith(c.id));
    });
}

// Determine if 2 entities have the same parent
export function areSiblings(entity1, entity2) {
    const hierarchy1 = entity1.id.split('.');
    const hierarchy2 = entity2.id.split('.');
    const levels = hierarchy1.length;
    if (hierarchy2.length !== levels) return false;
    for (let i = 0; i < levels - 1; i++) {
        if (hierarchy1[i] !== hierarchy2[i]) {
            return false;
        }
    }
    return true;
}

export function sortEntities(entity1, entity2, entities) {
    if (areSiblings(entity1, entity2)) { // Sort by name
        return entity1.fr_name.localeCompare(entity2.fr_name);
    } else {
        const hierarchy1 = entity1.id.split('.');
        const hierarchy2 = entity2.id.split('.');
        let index_diff = -1;
        for (let i = 0; i < Math.min(hierarchy1.length, hierarchy2.length); i++) {
            if (hierarchy1[i] !== hierarchy2[i]) {
                index_diff = i;
                break;
            }
        }
        if (index_diff <= 0) { // Sort by id
            return entity1.id.localeCompare(entity2.id);
        }
        const parent_id1 = hierarchy1.slice(0, index_diff + 1).join('.');
        const parent_id2 = hierarchy2.slice(0, index_diff + 1).join('.');
        const parent1 = entities.find(e => e.id === parent_id1);
        const parent2 = entities.find(e => e.id === parent_id2);
        if (!parent1 || !parent2) return 0;
        // Sort by parent name
        return parent1.fr_name.localeCompare(parent2.fr_name);
    }
}

// Get entities structure for visualization
export function getEntities(categories, entities_data, language=DEFAULT_LANGUAGE) {
    let entities = [];   
    // Get entities, building their hierarchical ids
    entities_data.forEach(entity => {
        if (!categories.find(c => c.id === entity.id.toString())) { // Categories are already entities
            const entityKeys = getHierarchicalKeys(categories, entities_data, entity, [entity.id.toString(), entity.fr_name], language);
            const entity_object = {id: entityKeys[0], sortKey: slugify(entityKeys[1]), fr_name: entity.fr_name, short_name: entity.short_name};
            entities.push(entity_object);
        }
    });
    categories.forEach(c => {
        const entityKeys = getHierarchicalKeys(categories, entities_data, c, [c.id.toString(), c.fr_name], language);
        const category_entity = {id: c.id, fr_name: c.fr_name, short_name: c.short_name, sortKey: slugify(entityKeys[1])};
        if (entities.find(e => e.id.startsWith(c.id))) { //"Does the category have children?"
            category_entity['category_title'] = true; // Set as a category (a separated arc)          
            entities.push(category_entity);
        }
    });
    // Sort entities alphabetically inside each category (sorting categories alphabetically too)
    entities = entities.slice(0, entities.length).sort((entity1, entity2, language) => {
        const key1 = entity1.sortKey !== undefined ? entity1.sortKey : entity1.fr_name;
        const key2 = entity2.sortKey !== undefined ? entity2.sortKey : entity2.fr_name;
        return key1.localeCompare(key2);
    });
    // Separate maroc and french institutions
    const isFrance = (e) => e.fr_name === "France"
    const france_id = entities.find(e => isFrance(e))?.id
    let marocInstitutions = []
    let frenchInstitutions = []

    entities.forEach(entity => {
        if (entity.id.startsWith(france_id)) {
            frenchInstitutions.push(entity)
        }
        else {
            marocInstitutions.push(entity)
        }
    });    

    entities = marocInstitutions.concat(frenchInstitutions);   
    return entities;
}

export function getHierarchicalId(categories, entities_data, entity, current_result) {
    if (categories.find(c => c.fr_name === entity.fr_name)) {
        return current_result;
    }
    const parent_id = entity.parent;
    const parent = entities_data.find(e => e.id === parent_id);
    if (parent_id === null || parent === undefined) {
        const country = getEntityCountry(entity);
        if (country) return `${country.natural_name}.${current_result}`;
        return current_result;
    }
    return getHierarchicalId(categories, entities_data, parent, `${parent_id}.${current_result}`);
}

export function getHierarchicalKeys(categories, entities_data, entity, current_result, language) {
    const parent_id = entity.parent;
    const parent = entities_data.find(e => e.id === parent_id);
    
    if (entity.id.toString().includes(".") && entity.id.split(".").length === 2) {       
        const country = getEntityCountry(entity);       
        if (country) {
            const countryKey = `fr_name`      
            return [
                `${country.natural_name}.${current_result[0]}`,
                `${country[countryKey]}.${current_result[1]}`
            ];
        }
    }

    if (parent_id === null || parent === undefined) {
        const region = getEntityRegion(entity);
        const country = getEntityCountry(entity);
        if (region && country) {
            const regionKey = `fr_name`  
            const countryKey = `fr_name`             
            return [
                `${country.natural_name}.${region.natural_name}.${current_result[0]}`,
                `${country[countryKey]}.${region[regionKey]}.${current_result[1]}`
            ];
        }
        return current_result;
    }
    const tail = [
        `${parent_id}.${current_result[0]}`,
        `${parent.fr_name}.${current_result[1]}`
    ];
    return getHierarchicalKeys(categories, entities_data, parent, tail, language);
}

// Get collaboration types for visualization
export function getCollabTypes(programs, language) {
    let formattedPrograms = (programs || []).map(program => {
        const hierarchicalId = program.id.toString();
        const nameKey = `${language}_name`;
        let color = undefined;
        for (var a in teaching_area_default_values) {
            if (program?.fr_name === teaching_area_default_values[a].label_fr) {
                color = teaching_area_default_values[a].color;
            }
        }
        return ({
            id: hierarchicalId,
            originalId: program.id.toString(),
            name: program[nameKey],
            order: program.order,
            color,
            id_name: program.fr_name
        });
    });
    return formattedPrograms
}
 

function getAction(actionId, actions) {
    return (actions || []).find(a => a.id === actionId);
}

// Get links structure for visualization
export function getLinks(collaborations, entities, actions, collab_types) {
    let links = [];
    (collaborations || []).forEach(col => {
        // Getting hierarquical ids
        let entity1_hierarchical_id = '';
        let entity2_hierarchical_id = '';
        entities.forEach(e => {
            if (e.id === col[0].toString() || e.id?.endsWith(`.${col[0]}`)) {
                entity1_hierarchical_id = e.id;
            } else if (e.id === col[1].toString() || e.id?.endsWith(`.${col[1]}`)) {
                entity2_hierarchical_id = e.id;
            }
        });
        const action = getAction(col[2], actions);
        const prog = collab_types.find(c => c.id === action?.external_program.toString() || c.id.endsWith(`.${action?.external_program.toString()}`))?.id_name;
        if (entity1_hierarchical_id !== '' && entity2_hierarchical_id !== '') {
            links.push({
                id1: entity1_hierarchical_id,
                id2: entity2_hierarchical_id,
                type: prog || OTHER_LINK_TYPE
            });
        }
    });
    return links;
}

function getActionTableData(actions, programs, entities, language) {
    return actions.map(action => {
        const program = programs.find(p => p.id === action.external_program) || {};
        const partners = entities.filter(
            e => action.partners.includes(e.id)
        )
        const partnersString = partners.map(e => e.fr_name).sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        }).join(', ');

        const partnersNames = action.partners.map(p =>
            (entities || []).find(e => e.id.toString() === p.toString() || (e.id?.toString()).endsWith(`.${p}`))?.fr_name
        );
        return {
            name: action.accord_id,
            level_diploma: action.level_diploma,
            admission_level: action[`${language}_admission_level`] || action[`${DEFAULT_LANGUAGE}_admission_level`],  
            diploma_title: action[`${language}_diploma_title`] || action[`${DEFAULT_LANGUAGE}_diploma_title`], 
            instruction_language: action.instruction_language,
            language_details: action[`${language}_language_details`] || action[`${DEFAULT_LANGUAGE}_language_details`], 
            application_period: action[`${language}_application_period`] || action[`${DEFAULT_LANGUAGE}_application_period`], 
            validation_period: action.validation_period,
            link_brochure: action.link_brochure,
            description: action[`${language}_description`] || action[`${DEFAULT_LANGUAGE}_description`], 
            program: program[`${language}_name`] || program[`${DEFAULT_LANGUAGE}_name`], 
            programId: program.id?.toString(),
            partnersArray: action.partners,
            partners: partnersString,
            partnersDetail: partners,
            partnersNames,
        };
    });
}

function getInitialState() {
   return {
        instruction_language_checkbox_values: language_instruction_default_values,
        level_diploma_checkbox_values: level_diploma_default_values,
        teaching_area_checkbox_values: teaching_area_default_values,
        categories: [],
        entities: [],
        collab_types: [],
        links: [],
        actions: [],
        loading: false,
        clickedEntity: ''
    };
}

function getLanguageInstructionValues(checkbox_values) {    
    let language_list = []
    for (var i =0; i < checkbox_values?.length; i ++){
        let language_obj = {}
        for (var j=0; j < Object.keys(checkbox_values[i]).length; j ++){
            language_obj[Object.keys(checkbox_values[i])[j]] = checkbox_values[i][Object.keys(checkbox_values[i])[j]]
        }
        language_list.push(language_obj);
    }
    return language_list;
}

function getLevelDiplomaValues(checkbox_values) {
    let level_list = []
    for (var i =0; i < checkbox_values?.length; i ++){
        let level_obj = {}
        for (var j=0; j < Object.keys(checkbox_values[i]).length; j ++){
            level_obj[Object.keys(checkbox_values[i])[j]] = checkbox_values[i][Object.keys(checkbox_values[i])[j]]
        }
        level_list.push(level_obj);
    }
    return level_list;
}

function getTeachingAreaValues(checkbox_values) {
    let area_list = []
    for (var i =0; i < checkbox_values?.length; i ++){
        let level_obj = {}
        for (var j=0; j < Object.keys(checkbox_values[i]).length; j ++){
            level_obj[Object.keys(checkbox_values[i])[j]] = checkbox_values[i][Object.keys(checkbox_values[i])[j]]
        }
        area_list.push(level_obj);
    }
    return area_list;
}

class VisualizationContainer extends React.Component {
    state = {
        signed_user: false,
        requestData: {},
        tab_value: 0,
        ...getInitialState()
    };

    setInitialStates(signed_user) {
        this.setState({
            signed_user,
            ...getInitialState()
        });
        this.loadData();
    }

    componentStart() {
        this.setInitialStates(this.state.signed_user)
    }

    componentDidMount() {
        this.componentStart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search_event !== this.props.search_event) {
            this.handleResetFilters();
        }
        if (prevProps.language !== this.props.language) {
            this.setState({loading: true},
            () => {
                this.updateDataForVisualization();
            });
        }
    }

    getWorkArea() {
        let work_area_list = [];
        let work_area_string = work_area_list.join();
        if (work_area_list.length && work_area_string[0] === ",") {
            work_area_string = work_area_string.substring(1);
        }
        if (work_area_string.split(',').length && work_area_string.split(',').length >= 2) {
            return null;
        }
        return work_area_string === "" ? "-" :  work_area_string;
    }

    loadData() {
        const {language, search_values} = this.props;
        this.setState({loading: true});
        return visualizationDataService.getAll(
            this.state, 
            {
                search_category: ALL_CATEGORIES,
                search_values: search_values.map(
                    v=> {
                        if (language === DEFAULT_LANGUAGE || v[`${ENGLISH}_name`] === "") {
                            return v[`${DEFAULT_LANGUAGE}_name`]
                        }
                        else {
                            return v[`${language}_name`]
                        }
                    }
                ),
                language
            })
            .then(res => {
                this.setState(state => ({
                    requestData: {
                        ...res.data,
                        language_instruction_checkbox_values: getLanguageInstructionValues(language_instruction_default_values),
                        level_diploma_checkbox_values: getLevelDiplomaValues(level_diploma_default_values),
                        teaching_area_checkbox_values: getTeachingAreaValues(teaching_area_default_values)
                    }
                }), () => this.updateDataForVisualization());
            })
            .catch(err => {
                this.setState({
                    loading: false,
                });
                console.log(err);
            }); 
    }
    

    updateDataForVisualization() {
        const currentLanguageCheckbox = this.state.instruction_language_checkbox_values;
        const currentLevelCheckbox = this.state.level_diploma_checkbox_values;
        const {language} = this.props;
        const {requestData} = this.state;
        const nameKey = `${language}_name`
        const descriptionKey = `description_${language}`
        const labelKey = `label_${language}`
        const od = requestData;
        const data = {
            ...od,
            actions: (od.actions || []).map(a => ({...a, name: a[nameKey]})),
            programs: (od.programs || []).map(p => ({...p, name: p[nameKey], description: p[descriptionKey]})),
            language_instruction_checkbox_values: (currentLanguageCheckbox || []).map(s => ({...s, label: s[labelKey]})),
            level_diploma_checkbox_values: (currentLevelCheckbox || []).map(s => ({...s, label: s[labelKey]})),
            teaching_area_checkbox_values: (this.state.teaching_area_checkbox_values || []).map(s => ({...s, label: s[labelKey]})),
        };
        this.getDataForVisualization(data, language);
    }


    getDataForVisualization(data, language) {
        let actions = data.actions;
        let entities_data = data.entities;       
        // Remove entities with no zone and parent
        entities_data = (entities_data || []).filter(ed => ed.zone || ed.parent);
       
        // chart categories: top level group elements (countries and Inria Chile)
        let categories = getCategories(entities_data || [], language);
        let entities = getEntities(categories, entities_data || [], language);
        categories = filterUsedCategories(categories, entities) || [];

        const collab_types = getCollabTypes(data.programs, language);

        let links = getLinks(data.collaborations, entities, actions, collab_types);

        actions = getActionTableData(actions, data.programs, entities_data, language);

        let clickedEntity = this.state.clickedEntity;
        if (clickedEntity !== '' && !entities.some(e => e.id === clickedEntity)) {
            clickedEntity = '';
        }

        this.setState({
            loading: false,
            states: data.states,
            entities, categories, collab_types, links, actions, clickedEntity     
        });
    }

    resetLegend() {
        const {legend_checks, actions} = this.props;
        const new_eye_checks = {};
        for (const item in legend_checks) {
            new_eye_checks[item] = true;
        }
        actions.saveChecks(new_eye_checks)
    }

    handleResetFilters() {
        this.resetLegend();
        this.setState(getInitialState(), () => {
            this.loadData();
        })
    }

    handleFilterChange() {
        return (value, filterId) => {
            this.setState({
                    [filterId]: value
            }, () => this.loadData());
        };
    }

    onDiplomaLevelChange() {
        return (item, checked) => {
            const {level_diploma_checkbox_values} = this.state;
            
            // Making a copy of the checkbox objects
            let new_level_diploma_checkbox_values =  []
            for (var i = 0; i < level_diploma_checkbox_values.length; i++) {
                let checkbox_obj = {}
                for (var key in level_diploma_checkbox_values[i]) {
                    checkbox_obj[key] = level_diploma_checkbox_values[i][key];
                }
                new_level_diploma_checkbox_values.push(checkbox_obj)
            }

            // Case all collaborations checkbox selected
            if (item.id === "") {
                for (var c = 0; c < new_level_diploma_checkbox_values.length; c ++) {
                    new_level_diploma_checkbox_values[c].checked = checked;
                }
            }
            // Case one checkbox selected/unselected
            else {
                for (var e = 0; e < new_level_diploma_checkbox_values.length; e ++) {
                    if (new_level_diploma_checkbox_values[e].label === item.label) {
                        new_level_diploma_checkbox_values[e].checked = checked;
                    }
                    else if(new_level_diploma_checkbox_values[e].id==="" && checked===false) {
                        new_level_diploma_checkbox_values[e].checked = false;
                    }
                }
                // Check main checkbox if needed
                let all_checked = true;
                let main_checkbox = new_level_diploma_checkbox_values.find(c => c.id==="");
                for (e = 1; e < new_level_diploma_checkbox_values.length; e ++) {
                    if (!new_level_diploma_checkbox_values[e].checked) {
                        all_checked = false;
                    }  
                }
                if (all_checked) {
                    main_checkbox.checked = true
                }
            }
            this.setState({
                level_diploma_checkbox_values: new_level_diploma_checkbox_values
            })
        };
    }

    onInstructionLanguageChange() {
        return (item, checked) => {
            const {instruction_language_checkbox_values} = this.state;

            // Making a copy of the checkbox objects
            let new_language_instruction_checkbox_values =  [] 
            for (var i = 0; i < instruction_language_checkbox_values.length; i++) {
                let checkbox_obj = {}
                for (var key in instruction_language_checkbox_values[i]) {
                    checkbox_obj[key] = instruction_language_checkbox_values[i][key];
                }
                new_language_instruction_checkbox_values.push(checkbox_obj)
            }

            // Case all collaborations checkbox selected
            if (item.id === "") {
                for (var c = 0; c < new_language_instruction_checkbox_values.length; c ++) {
                    new_language_instruction_checkbox_values[c].checked = checked;
                }
            }
            // Case one checkbox selected/unselected
            else {
                for (var e = 0; e < new_language_instruction_checkbox_values.length; e ++) {
                    if (new_language_instruction_checkbox_values[e].label === item.label) {
                        new_language_instruction_checkbox_values[e].checked = checked;
                    }
                    else if(new_language_instruction_checkbox_values[e].id==="" && checked===false) {
                        new_language_instruction_checkbox_values[e].checked = false;
                    }
                }
                // Check main checkbox if needed
                let main_checkbox = new_language_instruction_checkbox_values.find(c => c.id==="");
                let all_checked = true;
                for (e = 1; e < new_language_instruction_checkbox_values.length; e ++) {
                    if (!new_language_instruction_checkbox_values[e].checked) {
                        all_checked = false;
                    }
                }
                if (all_checked) {
                    main_checkbox.checked = true
                }
            }
            this.setState({
                instruction_language_checkbox_values: new_language_instruction_checkbox_values
            })
        };
    }

    onTeachingAreaChange() {
        return (item, checked) => {
            const {teaching_area_checkbox_values} = this.state;

            // Making a copy of the checkbox objects
            let new_teaching_area_checkbox_values =  []
            for (var i = 0; i < teaching_area_checkbox_values.length; i++) {
                let checkbox_obj = {}
                for (var key in teaching_area_checkbox_values[i]) {
                    checkbox_obj[key] = teaching_area_checkbox_values[i][key];
                }
                new_teaching_area_checkbox_values.push(checkbox_obj)
            }

            // Case all collaborations checkbox selected
            if (item.id === "") {
                for (var c = 0; c < new_teaching_area_checkbox_values.length; c ++) {
                    new_teaching_area_checkbox_values[c].checked = checked;
                }
            }
            // Case one checkbox selected/unselected
            else {
                for (var e = 0; e < new_teaching_area_checkbox_values.length; e ++) {
                    if (new_teaching_area_checkbox_values[e].label === item.label) {
                        new_teaching_area_checkbox_values[e].checked = checked;
                    }
                    else if(new_teaching_area_checkbox_values[e].id==="" && checked===false) {
                        new_teaching_area_checkbox_values[e].checked = false;
                    }
                }
                // Check main checkbox if needed
                let main_checkbox = new_teaching_area_checkbox_values.find(c => c.id==="");
                let all_checked = true;
                for (e = 1; e < new_teaching_area_checkbox_values.length; e ++) {
                    if (!new_teaching_area_checkbox_values[e].checked) {
                        all_checked = false;
                    }
                }
                if (all_checked) {
                    main_checkbox.checked = true
                }
            }
            this.setState({
                teaching_area_checkbox_values: new_teaching_area_checkbox_values
            })
        };
    }

    handleLabelClick() {
        return (event, entity) => {
            let value = entity.id;
            if (this.state.clickedEntity === entity.id) {
                value = '';
            }
            this.setState({
                    clickedEntity: value
            });
        };
    }

    handleChange = (_, tab_value) => {
        this.setState(
            {tab_value},
            () => {
                this.loadData();
            }
        );
    }

    render() {
        const {classes, language} = this.props;
        return (<div className={classes.root}>
            <Grid container justifycontent="flex-end" spacing={1}>
                <Grid item container xs={12}>
                    <VisualizationCanvas
                        level_diploma_checkbox_values={this.state.level_diploma_checkbox_values}
                        instruction_language_checkbox_values={this.state.instruction_language_checkbox_values}
                        program_checkbox_values={this.state.teaching_area_checkbox_values}
                        signed_user={this.state.signed_user}
                        language={language}
                        data={this.state}
                        handleFilterChange={this.handleFilterChange()}
                        onDiplomaLevelChange={this.onDiplomaLevelChange()}
                        handleLabelClick={this.handleLabelClick()}
                        loading={this.state.loading}
                        onReset={() => this.handleResetFilters()}
                        onInstructionLanguageChange={this.onInstructionLanguageChange()}
                        onTeachingAreaChange={this.onTeachingAreaChange()}
                    />
                </Grid>
            </Grid>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        search_values: state.searcherReducer.search_values,
        legend_checks: state.legendReducer.legend_checks,
        language: state.languageReducer.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({...languageActions, ...legendActions}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizationContainer));
