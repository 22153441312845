import React from 'react';
import { Grid} from '@material-ui/core';
import {ActionsDisplay} from './ActionsDisplay';

export class CardsDisplay extends React.Component {
    render(){
        const { signed_user, programs, language, actions} = this.props;
        return(
            <div>
                <Grid container style={{paddingBottom: 10}}>
                    <Grid item xs={12}>
                        <ActionsDisplay
                            signed_user={signed_user}
                            programs={programs}
                            language={language}
                            actions={actions}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };
}

export default CardsDisplay;
