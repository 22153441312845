import React from 'react';
import {ENGLISH, FRENCH} from './languages';
import { theme } from "../theme";

const LABELS = {
    [FRENCH]: {
        backToEmbassy: "Consulter le site de l'ambassade",
        brochure_button: "Consulter la brochure du cours",
        admissionLevel: "Niveau d'admission",
        country: "Pays",
        region: "Région",
        university: "Universités",
        faculty: "Faculté ou Ecole",
        filtersTitle: 'Filtres',
        languageInstruction: "Langue(s) d'enseignement",
        debut: 'Debut',
        fin: 'Fin',
        //diplomaLevel: 'Niveau des diplômes délivrés',
        teachingArea: "Domaine(s) d'enseignement",
        selectClearAll: 'Sélectionner/Effacer tout',
        //name: 'Name',
        partners: 'Partenaires',
        noWheelResults: 'Visualisation non disponible',
        showingCollaborations: (name) => (
            <>
                Afficher les accords entre <b>{name}</b> et <b>d'autres institutions</b>
            </>
        ),
        showingCollaborationsResults: (result) => (
            <>
                <span style={{color: theme.palette.primary.main, fontSize: 20}}>Accords</span> <br/>
                Nous avons trouvé <b>{result}</b> résultats
            </>
        ),
        showAll: 'Afficher tout',
        accords: 'Accords',
        projectDate: 'Project date',
        applicationPeriod: 'Période de candidature',
        validityPeriod: 'Période de validité de la convention',
        resetAll: 'Réinitialiser filtres',
        diplomas: 'Diplomes',
        diplomaTitle: 'Intitulé des diplomés',
        signOut: 'Sign out',
        signIn: 'Sign in',
        search_placeholder: "Recherche par accords, institutions ou intitulés...",
        public_search_placeholder: "Recherche par accords, institutions ou intitulés...",
        all_collab_tab: "All collaborations",
        science_tab: "Scientific Collaborations",
        innovation_tab: "Innovation Collaborations",
        screenshot_button: "Capture d'écran",
        switch_cards_left: "Fiches projet",
        switch_cards_rigth: "Panneau visuel"
    },
    [ENGLISH]: {
        backToEmbassy: "Visit the embassy website",
        brochure_button: "View the course brochure",
        admissionLevel: "Admission level",
        country: "Country",
        region: "Region",
        university: "Universities",
        faculty: "Faculty or School",
        filtersTitle: 'Filters',
        languageInstruction: 'Language of instruction',
        debut: 'Start',
        fin: 'End',
        //diplomaLevel: 'Level of diplomas awarded',
        teachingArea: 'Teaching area(s)',
        selectClearAll: 'Select/Clear all',
        //name: 'Nombre',
        partners: 'Partners',
        noWheelResults: 'Visualization not available',
        showingCollaborations: (name) => (
            <>
                Showing collaborations between <b>{name}</b> and <b>other entities</b>
            </>
        ),
        showingCollaborationsResults: (result) => (
            <>
                <span style={{color: theme.palette.primary.main, fontSize: 20}}>Agreements</span> <br/>
                We found <b>{result}</b> results
            </>
        ),
        showAll: 'Show all',
        accords: 'Agreements',
        projectDate: 'Project date',
        applicationPeriod: 'Application period',
        validityPeriod: 'Validity period',
        resetAll: 'Reset filters',
        diplomas: 'Diplomas',
        diplomaTitle: 'Title of the diploma',
        signOut: 'Sign out',
        signIn: 'Sign in',
        search_placeholder: "Search by agreements, institutions or titles...",
        public_search_placeholder: "Search by agreements, institutions or titles...",
        all_collab_tab: "All collaborations",
        science_tab: "Scientific Collaborations",
        innovation_tab: "Innovation Collaborations",
        screenshot_button: "Screenshot",
        switch_cards_left: "Project cards",
        switch_cards_rigth: "Visual board"
    }
};

export function getLabels(language) {
    if ([ENGLISH, FRENCH].includes(language)) {
        return LABELS[language];
    }
    return LABELS[FRENCH];
}
