import {SET_LANGUAGE} from '../actions/language.actionTypes';
import {DEFAULT_LANGUAGE} from '../constants/languages';

export const initialState = {
    language: DEFAULT_LANGUAGE
};

export const languageReducer = (state = initialState, action = {}) => {
    if (action.type === SET_LANGUAGE) {
        return {
            ...state,
            language: action.language
        };
    }
    else {
        return state;
    }
};
