import http from "./http-common";
import {DEFAULT_LANGUAGE} from '../constants/languages';


async function getAutocomplete(current_value, category, signed_user, language=DEFAULT_LANGUAGE) {
    return http.get(
      `/autocomplete/?current_value=${current_value}&category=${category}&language=${language}`
    );
}

const autocompleteService = {getAutocomplete}

export default autocompleteService;