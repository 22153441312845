export const teaching_area_default_values = [
    {
        id: "",
        label: "Domaine(s) d'enseignement",
        label_fr: "Domaine(s) d'enseignement",
        label_en: "Teaching areas",
        checked: true,
        color: ""
    },
    {
        id: "Science and technology",
        label: "Sciences et technologies",
        label_fr: "Sciences et technologies",
        label_en: "Science and technology",
        checked: true,
        color: "#0094EF"
    },
    {
        id: "Letters, languages and arts",
        label: "Lettres, langues et arts",
        label_fr: "Lettres, langues et arts",
        label_en: "Letters, languages and arts",
        checked: true,
        color: "#FF9100"
    },
    {
        id: "Humanities and social sciences",
        label: "Sciences humaines et sociales",
        label_fr: "Sciences humaines et sociales",
        label_en: "Humanities and social sciences",
        checked: true,
        color: "#AD3ADD"
    },
    {
        id: "Law and political sciences",
        label: "Droit et sciences politiques",
        label_fr: "Droit et sciences politiques",
        label_en: "Law and political sciences",
        checked: true,
        color: "#E63312"
    },
    {
        id: "Economy and management",
        label: "Economie et gestion",
        label_fr: "Economie et gestion",
        label_en: "Economy and management",
        checked: true,
        color: "#001384"
    },
    {
        id: "Health",
        label: "Santé",
        label_fr: "Santé",
        label_en: "Health",
        checked: true,
        color: "#ED1568"
    },
    {
        id: "Sport",
        label: "Sport",
        label_fr: "Sport",
        label_en: "Sport",
        checked: true,
        color: "#0BB751"
    }
]
