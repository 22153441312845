import React from 'react';
import autocompleteService from '../services/autocompleteService';
import Searcher from '../components/Searcher';
import {connect} from 'react-redux';
import {searcherActions} from '../actions/searcher.actionCreators';
import {bindActionCreators} from 'redux';
import {ALL_CATEGORIES} from '../constants/categories';
import {DEFAULT_LANGUAGE} from '../constants/languages';

class SearcherContainer extends React.Component {
    state = {
        tags: [],
        input_value: "",
        autocomplete_options: []
    };

    getOptionLabel() {
        const {language} = this.props;
        return language === DEFAULT_LANGUAGE ? `${language}_name` : `${language}_name`;
    }

    onApplySearch(values) {
        const {input_value} = this.state;
        const {actions, notifySearchEvent} = this.props;
        let searched_values = values;
        if (input_value !== "") {
            searched_values = [...values, input_value]
        }
        this.setState(
            {tags: searched_values, input_value: ""},
            () => {
                actions.setSearchValues(searched_values);
                notifySearchEvent();
            }
        );
    }

    setNewTag(values, input_value="") {
        let tags = values;
        const option_label = this.getOptionLabel();
        const input_val_obj = {[option_label]: input_value};
        if (input_value !== "") {
            tags = [...values, input_val_obj];
        }
        this.setState({
            tags,
            input_value: "" //input_value !== "" ? input_val_obj : ""
        });
    }

    onInputChange(value) {
        const option_label = this.getOptionLabel();
        this.setState({input_value: value === "" ? value : {[option_label]: value}})
    }

    loadAutocomplete(value) {
        const { signed_user, language } = this.props;
        return autocompleteService.getAutocomplete(value, ALL_CATEGORIES, signed_user, language)
            .then(res => {
                this.setState({
                    autocomplete_options: res.data
                })
            })
            .catch(err => console.log(err));
    }

    onClear() {
        // Take the Reference of Close Button
        const close = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
        if (close) {
            // Add a Click Event Listener to the button
            close.addEventListener("click", () => {
                this.setState({
                    tags: [],
                    input_value: ""
                }, () => this.onApplySearch([]));
            });
        }
    }

    removeTag(tag) {
        this.setState(
            {tags: this.state.tags.filter(t => t !== tag)},
            () => this.onApplySearch(this.state.tags)
        );
    }

    componentDidMount() {
        this.onClear();
    }

    render() {
        const {tags, autocomplete_options, input_value} = this.state;
        const option_label = this.getOptionLabel();
        return (
            <Searcher tags={tags} setTag={(v, iv) => this.setNewTag(v, iv)}
                onApplySearch={(v) => this.onApplySearch(v)}
                loadAutocomplete={(v) => this.loadAutocomplete(v)}
                onInputChange={(v) => this.onInputChange(v)}
                onTagRemove={(t) => this.removeTag(t)}
                options={autocomplete_options}
                option_label={option_label}
                input_value={input_value === "" ? "" : input_value[option_label]}
                language={this.props.language}
                signed_user={this.props.signed_user}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.languageReducer.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(searcherActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearcherContainer);
