import { theme } from "../theme";

// Category colors
export const INRIA_COLOR = "#b0a095";
export const OTHER_COLOR = "#84B6B4";

// Text colors
export const TEXT_COLOR = "#282929";

// Switch color
export const SWITCH_COLOR = theme.palette.secondary.main;

// Link colors
export const DEFAULT_LINK_COLOR = "#000000";

// Arc colors
export const LEVEL0_ARC_COLOR = "#3a4259"
export const REGION_ARC_COLOR = "#f50100"
export const MAROC_UNIVERSITY_ARC_COLOR = "#b0a095";
export const FRANCE_COLOR = "#0e0096"
export const MAROC_COLOR = "#009b52"
export const INDICATOR_COLOR = "#444444"
