import {NEW_LEGEND} from '../actions/legend.actionTypes';

export const initialState = {
    legend_checks: {}
};

export const legendReducer = (state = initialState, action = {}) => {
    if (action.type === NEW_LEGEND) {
        return {
            ...state,
            legend_checks: action.legend_checks
        };
    }
    else {
        return state;
    }
};
