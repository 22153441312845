const local = (key, absent = null) => window?.configuration?.[key] ?? absent;

const prefix = 'REACT_APP_';

const env = (key, absent = null) => {
    const composite = `${prefix}${key}`;
    return Object.keys(process.env).indexOf(composite) === -1 ?
        absent :
        process.env[composite];
};

export const API_HOST = local('API_HOST', env('API_HOST', 'http://localhost:8000/api'));
/*const COGNITO_AWS_REGION = local('COGNITO_AWS_REGION', env('COGNITO_AWS_REGION', ""));
const COGNITO_USER_POOL = local('COGNITO_USER_POOL', env('COGNITO_USER_POOL', ""));
const COGNITO_CLIENT_ID = local('COGNITO_CLIENT_ID', env('COGNITO_CLIENT_ID', ""));

export const COGNITO_LOCAL_CONFIG = {
    "Auth": {
        region: COGNITO_AWS_REGION,
        userPoolId: COGNITO_USER_POOL,
        userPoolWebClientId: COGNITO_CLIENT_ID
    }
}

export const COGNITO_CONFIG = local('COGNITO_CONFIG', COGNITO_LOCAL_CONFIG);*/