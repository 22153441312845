const PUBLIC = "PUBLIC";
const SIGN_IN = "SIGN_IN";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const VERIFY = "VERIFY";
const VERIFY_SUBMIT = "VERIFY_SUBMIT";
const RESET_PASSWORD = "RESET_PASSWORD";
const RESET_SUBMIT = "RESET_SUBMIT";
const WELCOME = "WELCOME";

export const states = {
    PUBLIC,
    SIGN_IN,
    CHANGE_PASSWORD,
    VERIFY,
    VERIFY_SUBMIT,
    RESET_PASSWORD,
    RESET_SUBMIT,
    WELCOME
}