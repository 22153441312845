import http from "./http-common";


async function getAll(data, search_params) {
    
    return http.get(`/collaboration/chart/`
      + `?search_category=${search_params.search_category}`
      + `&search_values=${search_params.search_values.length > 0 ? search_params.search_values.toString() : ''}`
      + `&language=${search_params.language}`
    );
}

const visualizationService = {getAll}

export default visualizationService;
