import React from 'react';
import {CircularProgress, Dialog, Link, DialogContent, Typography, withStyles, Button, Switch} from '@material-ui/core';
import {Grid} from '@mui/material';
import CollVis from './CollVis';
import CheckboxGroup from './utils/CheckboxGroup';
import {legendActions} from '../actions/legend.actionCreators';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ArrowBack} from '@material-ui/icons';
import blueGrey from '@material-ui/core/colors/blueGrey';
import {getLabels} from '../constants/labels';
import TakeScreenshot from './TakeScreenshot';
import CardsDisplay from './CardsDisplay';
import {SWITCH_COLOR} from '../constants/colors';
import resetIcon from './icons/resetIcon.svg';
import filtersIcon from './icons/filtersIcon.svg';


const styles = theme => ({
    canvas: {
        flexGrow: 1,
        marginBottom: 10,
    },
    leftSideHeader :{
        padding: "28px 0px 20px 0px"
    },
    leftSide: {
        backgroundColor: 'white',
    },
    title: {
        color: theme.palette.primary.main,
        paddingLeft: 27,
        fontSize: 20,
        fontWeight: "bold"
    },
    filterTitle: {
        paddingRight: 10,
        fontWeight: 'bold'
    },
    filter: {
        width: '100%',
        paddingBottom: 25
    },
    rightSide: {
        position: 'relative',
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingBottom: 90
    },
    visItem: {
        paddingRight: 10,
        height: "auto",
        minHeight: "100%",
        display: "contents"
        /*[theme.breakpoints.down('sm')]: {
            padding: '0 0%'
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 0%'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 5%'
        }*/
    },
    dialogContent: {
        padding: '0px 20px 15px 20px'
    },
    hidden: {
        visibility: 'hidden'
    },
    noWheelResults: {
        textAlign: 'center',
        padding: 30
    },
    resetIcon: {
        color: theme.palette.secondary.main,
    },
    resetAll: {
        color: theme.palette.secondary.main,
        paddingRight: 10,
        fontSize: 11,
        textDecoration: "underline"
    },
    switchLabelFocus: {
        textDecoration: "underline",
        color: theme.palette.primary.main
    },
    switchDetail: {
        height: 48,
        width: 68
    },
    switchBase: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&$checked': {
            '&:hover': {
                backgroundColor: 'transparent !important'
            },
            '& + $track': {
              backgroundColor: SWITCH_COLOR,
              opacity: 1
            }
        },
        '& + $track': {
            backgroundColor: SWITCH_COLOR,
            opacity: 1
        }
    },
    thumb: {
       marginTop: 5,
       marginLeft: 5,
       color: 'white',
       width: 20,
       height: 20,
    },
    track: {
        borderRadius: 48 / 2,
    },
    checked: {},
    focusVisible: {}
});

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 0,
        textTransform: 'Capitalize',
        '&:hover': {
            backgroundColor: blueGrey[50]
        },
        zIndex: 1
    }
}))(Button);

export class VisualizationCanvas extends React.Component {

    state = {
        teaching_areas: [],
        checked_teaching_areas: {},
        filteredActions: [],
        filteredEntities: [],
        legendDisplayables: {},
        switchState: true,
        area_data: []
    };

    handleChangeSwitch(){
        this.setState((prevState) => ({ switchState: !prevState.switchState }));
    }
    

    loadData() {
        const {actions} = this.props;
        const teaching_areas = this.getCollabTypes();
        const checked_teaching_areas = this.getCheckedCollabTypes(teaching_areas);
        const legendDisplayables = this.getDisplayableLegends(teaching_areas);
        this.setState({teaching_areas, checked_teaching_areas, legendDisplayables}, () => {
            if (actions && Object.keys(checked_teaching_areas).length !== 0
                && this.legendCheckshaveChanged(checked_teaching_areas)) {
                actions.saveChecks(checked_teaching_areas);
            }
            this.filterActionsAndEntities();
        });
    }

    legendCheckshaveChanged(checked_teaching_areas = {}) {
        const {legend_checks} = this.props;
        if (!legend_checks || !legend_checks) return true;
        for (const [key, value] of Object.entries(checked_teaching_areas)) {
            if (legend_checks[key] === undefined) return true;
            if (legend_checks[key] !== value) return true;
        }
        return false;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) this.loadData();
    }

    getCollabTypes() {
        const {data} = this.props;
        const teaching_areas = (data.collab_types || []).map(prog => {
            let quantity = 0;
            (data.actions || []).forEach(a => {
                if (a.programId === prog.id.toString() || prog.id.toString().endsWith(`.${a.programId}`)) {
                    quantity = quantity + 1;
                }
            });
            if (quantity === 0) quantity = undefined;
            return {...prog, quantity};
        });
        return teaching_areas;
    }

    getCheckedCollabTypes(collab_types) {
        const {legend_checks} = this.props;
        let checked_collabTypes = {};
        let current2ndLevelId = '';
        let current2ndLevelValue = true;
        (collab_types || []).forEach(t => {
            let value = true;
            if(t.id.startsWith(current2ndLevelId)) {
                value = current2ndLevelValue;
            }
            const storedValue = legend_checks?.[t.id];
            checked_collabTypes[t.id] = storedValue !== undefined ? storedValue : value;
            if (t.id.split('.').length === 2) {
                current2ndLevelId = t.id;
                current2ndLevelValue = checked_collabTypes[t.id];
            }
        });
        return checked_collabTypes;
    }

    getDisplayableLegends(collab_types) {
        if (Object.keys(this.state.legendDisplayables).length !== 0) {
            return this.state.legendDisplayables;
        }
        let displayable = {};
        (collab_types || []).forEach(t => {
            displayable[t.id] = false;
        });
        return displayable;
    }

    // Discards links between entities from same country
    getFilteredLinks() {
        const {data} = this.props;
        return (data.links || []).filter(l => {
            const hierarchical_ids1 = l.id1.split('.');
            const hierarchical_ids2 = l.id2.split('.');
            return (!(
                hierarchical_ids1.length >= 1 && hierarchical_ids2.length >= 1 &&
                hierarchical_ids1[0] === hierarchical_ids2[0]
            ));
        });
    }



    getFilteredActionsByProgram() {
        const {data} = this.props;
        const {checked_teaching_areas} = this.state;
        return (data.actions || []).filter(a => {
            const hierarchicalId = Object.keys(checked_teaching_areas).find(t =>
                t === a.programId || t.endsWith(`.${a.programId}`)
            );
            return checked_teaching_areas[hierarchicalId];
        });
    }

    filterActionsByClickedEntity(actions, clickedEntity) {
        if (clickedEntity === '') {
            return actions;
        }
        const {data: {entities}} = this.props;
        return actions.filter(
            a => {
                const clickedPartner = (a.partnersArray || []).find(p => {
                    const entity = entities.find(e => e.id.endsWith(p));
                    if (!entity) return false;
                    return clickedEntity === entity.id || entity.id.startsWith(clickedEntity);
                });
                return clickedPartner !== undefined;
            }
        );
    }

    getFilteredEntities(filtered_actions) {
        const {data: {entities, clickedEntity}, handleLabelClick} = this.props;
        // If all programs are shown return the full list
        const idsSet = new Set();
        // get list of partners in filtered actions
        filtered_actions.forEach(a => (a.partnersArray || []).forEach(p => idsSet.add(p)));

        // add ids of every element in hierarchy
        Array.from(idsSet).forEach(eId => {
            const entity = entities.find(e => e.id.endsWith("." + eId));
            const hierarchy = entity?.id.split('.') || [];
            for (let x = 0; x < hierarchy.length; x++) {
                idsSet.add(hierarchy.slice(0, x + 1).join('.'));
            }
        });
        // if filtered entities do not include de selected entity,
        // clear the filter
        if (clickedEntity !== '' && !idsSet.has(clickedEntity)) {
            handleLabelClick({}, {id: clickedEntity});
        }
        // filter entities
        return entities.filter(e => idsSet.has(e.id));
    }

    /*getFilteredActionsByDiplomaLevel(actions) {
        const {level_diploma_checkbox_values} = this.props;
        const checked = level_diploma_checkbox_values.filter(v => v.checked);
        return actions.filter(a => {
            const diploma_levels = a.level_diploma.split(";");
            for (const i in diploma_levels) diploma_levels[i] = diploma_levels[i].trim();
            for (const i in checked) {
                if (diploma_levels.includes(checked[i].label_fr) || diploma_levels.includes(checked[i].label_en)) {
                    return true;
                }
            }
            return false;
        })
    }*/

    getFilteredActionsByCheckboxes(actions, key) {
        const prop_name = key + "_checkbox_values";
        const checked = this.props[prop_name].filter(v => v.checked);
        return actions.filter(a => {
            const action_values = a[key].split(";");
            for (const i in action_values) action_values[i] = action_values[i].trim();
            for (const i in checked) {
                if (action_values.includes(checked[i].label_fr) || action_values.includes(checked[i].label_en)) {
                    return true;
                }
            }
            return false;
        })
    }

    handleLegendDisplay(itemId) {
        this.setState({
            legendDisplayables: {
                ...this.state.legendDisplayables,
                [itemId]: !this.state.legendDisplayables[itemId]
            }
        });
    }

    filterActionsAndEntities() {
        const {data: {clickedEntity}} = this.props;
        const filteredActionsByProgram = this.getFilteredActionsByProgram();
        let filteredActions = this.getFilteredActionsByCheckboxes(filteredActionsByProgram, "level_diploma")
        filteredActions = this.getFilteredActionsByCheckboxes(filteredActions, "instruction_language")
        const area_data = this.getTeachingAreaFilterData(filteredActions); // Take filteredaction before applying prpgram filter
        filteredActions = this.getFilteredActionsByCheckboxes(filteredActions, "program")
        const filteredEntities = this.getFilteredEntities(filteredActions);
        // Clicked entity doesn't hide other entities in the wheel, so filteredEntities is calculated before
        filteredActions = this.filterActionsByClickedEntity(filteredActions, clickedEntity);
        this.setState({
            filteredActions, filteredEntities, area_data
        });
    }

    getTeachingAreaFilterData(filteredActions) {
        const {program_checkbox_values, language} = this.props;
        let area_data = [];
        const label_key = language ? `label_${language}` : "label"
        // Copy teaching area data
        for (var a in program_checkbox_values) {
            let item = {};
            const keys = Object.keys(program_checkbox_values[a]);
            for (var k in keys) {
                item[keys[k]] = program_checkbox_values[a][keys[k]]
            }
            item["count"] = 0
            area_data.push(item);
        }
        // Count data from accord
        for (a in filteredActions) {
            const teachingArea = filteredActions[a].program;
            let checkboxItem = area_data.find(a => a[label_key] === teachingArea);
            if (checkboxItem) {
                checkboxItem["count"] = checkboxItem?.["count"] + 1
            }
        }
        return area_data;
    }

    render() {
        const {data, onDiplomaLevelChange, onInstructionLanguageChange, onTeachingAreaChange, handleLabelClick, loading, language, onReset, signed_user} = this.props;
        const classes = this.props.classes || {};
        const {teaching_areas, filteredActions, filteredEntities, area_data, switchState} = this.state;
        const data_links = this.getFilteredLinks();
        const clickedEntityObj = filteredEntities.find(e => e.id === data.clickedEntity) || {name: ''};
        const labels = getLabels(language);
        return (<>
            <Grid container className={classes.canvas} spacing={1}>
                <Grid item container xs={3}>
                    <Grid item container className={classes.leftSide} direction="column">
                            <Grid container item direction="column" className={classes.leftSideHeader}>
                                <Grid item container className={classes.title} direction="row">
                                    <Grid item>
                                        <img src={filtersIcon} alt="reset" style={{width: 30, marginRight: '3px'}}/>
                                    </Grid>
                                    <Grid item>
                                        {labels.filtersTitle}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Link href="#" id="reset_all"  onClick={() => {onReset();}}>   
                                        <Grid container justifyContent="flex-end">
                                            <Grid item className={classes.resetIcon}>
                                                <img src={resetIcon} alt="reset" style={{width: 20, marginRight: '3px'}}/>
                                            </Grid>
                                            <Grid item className={classes.resetAll}>
                                                <b>{labels.resetAll}</b>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.filter}>
                                <CheckboxGroup
                                    language={language}
                                    onChange={onInstructionLanguageChange}
                                    data={data.instruction_language_checkbox_values}
                                />
                            </Grid>

                            <Grid item className={classes.filter}>
                                <CheckboxGroup
                                    language={language}
                                    onChange={onDiplomaLevelChange}
                                    data={data.level_diploma_checkbox_values}
                                />
                            </Grid>

                            <Grid item className={classes.filter}>
                                <CheckboxGroup
                                    language={language}
                                    onChange={onTeachingAreaChange}
                                    data={area_data}
                                />
                            </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={9}>
                    <Grid item container className={classes.rightSide}>
                        <Grid item xs={12} className={classes.visItem}>
                            <Dialog open={loading}>
                                <DialogContent className={classes.dialogContent}><CircularProgress/></DialogContent>
                            </Dialog>
                            <Grid container style={{height: 105}} justifyContent='space-between'>
                                <Grid item xs={6}>
                                    {data.clickedEntity !== '' ?
                                    (<>
                                        <Typography component="div" style={{paddingTop: 25}}>
                                            {labels.showingCollaborations(clickedEntityObj.fr_name)}
                                        </Typography>
                                        <ColorButton
                                            variant="contained"
                                            style={{marginTop: '10px'}}
                                            onClick={(e) => handleLabelClick(e, clickedEntityObj)}
                                            startIcon={<ArrowBack/>}
                                        >
                                            {labels.showAll}
                                        </ColorButton>
                                    </>):
                                        <Typography style={{paddingTop: 25}}>
                                            {labels.showingCollaborationsResults(filteredActions.length)}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid style={{paddingRight: 20}} item xs={6} container alignItems="center" justifyContent="flex-end">
                                    <Grid item className={!switchState ? classes.switchLabelFocus : null}>{labels.switch_cards_left}</Grid>
                                    <Grid item style={{width: 70}}>
                                        <Switch
                                            defaultChecked={true}
                                            disableRipple
                                            value={this.state.switchState}
                                            onChange={() => this.handleChangeSwitch()}
                                            focusVisibleClassName={classes.focusVisible}
                                            classes={{
                                                root: classes.switchDetail,
                                                switchBase: classes.switchBase,
                                                thumb: classes.thumb,
                                                track: classes.track,
                                                checked: classes.checked
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={switchState ? classes.switchLabelFocus : null}>{labels.switch_cards_rigth}</Grid>
                                </Grid>
                            </Grid>
                            {this.state.switchState ? 
                                (!loading && (!data.entities || data.entities.length === 0) ?
                                    <Typography className={classes.noWheelResults}>{labels.noWheelResults}</Typography> :
                                    <div className={classes.visItem}>
                                        <CollVis
                                                entities={filteredEntities}
                                                links={data_links}
                                                category_colors={data.categories}
                                                TeachingAreas={teaching_areas}
                                                checked={this.props.program_checkbox_values}
                                                handleLabelClick={handleLabelClick}
                                                clickedEntity={data.clickedEntity}
                                        />
                                        <TakeScreenshot language={this.props.language}/>
                                    </div>)
                                : 
                                (<CardsDisplay
                                    signed_user={signed_user}
                                    programs={data.collab_types}
                                    language={language}
                                    actions={filteredActions}
                                />)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>);
    }
}

const mapStateToProps = state => {
    return {
        legend_checks: state.legendReducer.legend_checks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(legendActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VisualizationCanvas));
