import React, {Component} from 'react';
import {FormControl, FormGroup, FormControlLabel, Checkbox, Typography, Grid} from '@mui/material';
import {theme} from "../../theme";
import {Circle} from '@mui/icons-material';


const styles = {
    form: {
        width: "100%",
    },
    itemFormMain: {
        backgroundColor : theme.palette.header,
        width: "100%",
        color: "white",
        marginLeft: 0
    },
    itemForm: {
        paddingLeft: 3,
        width: "100%",
        '.MuiFormControlLabel-label': {
            width: "100%",
            paddingRight: 3,
        }
    },
    checkbox: {
        marginLeft: 1,
        color: theme.palette.primary.main,
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
        '& .MuiSvgIcon-root': {
            zIndex: 1,
          },
          '& .PrivateSwitchBase-input': {
            width: "40%",
            height: "40%",
            top: 'auto',
            left: 'auto',
            opacity: 1,
            '&::before': {
              content: '""',
              position: 'absolute',
              background: 'white',
              height: "100%",
              width: "100%",
              visibility: "visible" // optional
            },
          }
    },
    mainLabel: {
        fontFamily: "Marianne",
        fontWeight: "bold"
    },
    label: {
        fontFamily: "Marianne",
        fontSize: 14
    },
    colorCount: {
        paddingTop: 0
    }
};

class CheckboxGroup extends Component {
    getLabelComponent(item) {
        const {language} = this.props;
        const label_key = language ? `label_${language}` : "label"
        if (item.count === undefined || item.id==="") {
            return <Typography sx={item.id==="" ? styles.mainLabel : styles.label}>{item[label_key]}</Typography>
        }
        else {
            return (
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={8}>
                        <Typography sx={styles.label}>{item[label_key]}</Typography>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end" style={{paddingTop: 8}}>
                        <Grid item xs={6} style={{textAlign: "right"}}>
                            <Circle sx={{color: item.color}}/>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: "center"}}>
                            <Typography sx={styles.label}>{item.count}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }
    getCheckBox(item, index) {
        const {checkboxProps, onChange = () => {}} = this.props;
        return (
            <FormControlLabel
                key={index}
                sx={item.id === "" ? styles.itemFormMain : styles.itemForm}
                control={
                    <Checkbox
                        {...checkboxProps}
                        checked={item.checked}
                        onChange={(event) => onChange(item, event.target.checked)}
                        inputProps={{
                            'data-testid': `checkbox-${index}-${item.label}`,
                        }}
                        sx={styles.checkbox}
                    />
                }
                label={this.getLabelComponent(item)}
            />
        );
    }

    render() {
        const {data, title, row, classes} = this.props;
        return (
            <FormControl component="fieldset" style={styles.form}>
                {title && <Typography className={classes.title}>
                    <b>{title}</b>
                </Typography>}
                <FormGroup row={row}>{(data || []).map((item, index) => {
                    if (item.count !== 0 || item.id === "") {
                       return this.getCheckBox(item, index)
                    }
                    else return null
                })}</FormGroup>
            </FormControl>
        );
    }
}

export default CheckboxGroup;
