import { combineReducers } from 'redux';
import {legendReducer} from './legendReducer';
import {languageReducer} from './languageReducer';
import {searcherReducer} from './searcherReducer';

const rootReducer = combineReducers({
    legendReducer,
    languageReducer,
    searcherReducer
});

export default rootReducer;
