import React from "react";
import {Autocomplete, TextField, IconButton, Chip, Grid} from '@mui/material'
import searchIcon from './icons/searchIcon.svg';
import {getLabels} from '../constants/labels';
import { DEFAULT_LANGUAGE, ENGLISH } from "../constants/languages";
import { theme } from "../theme";

const styles = {
  root: {
    display: "flex",
    alignItems: "center"
  },
  autocomplete: {
    width: "100%",
    ".MuiOutlinedInput-root": {
      backgroundColor: "white",
      borderRadius: 0,
      '& fieldset': {
        borderColor: 'grey',
        borderWidth: 1.5
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1.5
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 3
      }
    }
  },
  searchButton: {
    height: 48,
    width:45,
    marginTop: 0.4,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius:0,
  },
  tag: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300
  },
  textfield: {
    fontSize: 14,
    height:30
  }
};

function dropDuplicates(options, language) {
  let new_options = []
  const name_key = `${language}_name`;
  for (var o in options) {
    const option = options[o];
    const dup = new_options.find(d => d[name_key] === option[name_key]) 
    if (!dup) {
      new_options.push(option);
    }
  }
  return new_options;
}

export default function Searcher({ onApplySearch, loadAutocomplete, onTagRemove,
  tags, setTag, input_value, onInputChange, options, option_label, language, signed_user
}) {
  const labels = getLabels(language);
  const handleKeyDown = event => {
    switch (event.key) {
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          setTag(tags, event.target.value);
        }
        break;
      }
      case "Enter": {
        event.preventDefault();
        event.stopPropagation();
        onApplySearch(tags);
        (document.getElementById("tags-outlined")).blur(); // Unfocuses searcher input element
        break;
      }
      default:
    }
  };
  const new_options = dropDuplicates(options, language)
  return (
    <Grid container sx={styles.root} direction="row"> 
      <Grid item xs={11}>
      <Autocomplete
        multiple
        freeSolo
        id="tags-outlined"
        options={new_options}
        getOptionLabel={option => option[option_label] || option[`${DEFAULT_LANGUAGE}_name`]}
        value={tags}
        renderTags={(values) =>
          values.map((value) => {
            return <Chip key={`${value[option_label]}_${language}`} label={value[option_label] || value[`${DEFAULT_LANGUAGE}_name`] || value[`${ENGLISH}_name`]}
              sx={styles.tag}
              onDelete={() => {
                onTagRemove(value);
              }}
            />
          })
        }
        onChange={(event, newValue) => setTag(newValue)}
        sx={styles.autocomplete}
        filterSelectedOptions
        renderInput={(params) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <TextField 
              {...params}
              variant="outlined"
              placeholder={
                signed_user ?
                labels.search_placeholder :
                labels.public_search_placeholder
              }
              margin="dense"
              fullWidth
              size="small"
              inputProps={{ ...params.inputProps, style: styles.textfield, value: input_value}}
              value={input_value}
              onChange={(event) => {
                loadAutocomplete(event.target.value);
                onInputChange(event.target.value);
              }}
            />
          );
        }}
      />
      </Grid>
      <Grid item xs={1}>
      <IconButton
          sx={styles.searchButton}
          onClick={() => onApplySearch(tags)}
      >
        <img src={searchIcon} alt="icon" style={{width: 30}}/>
      </IconButton>
      </Grid>
    </Grid>
  );
}
