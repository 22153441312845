import { NEW_LEGEND } from './legend.actionTypes';

function saveChecks(legend_checks) {
    return {
        type: NEW_LEGEND,
        legend_checks
    }
}

export const legendActions = {
    saveChecks
}