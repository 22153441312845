export const level_diploma_default_values = [
    {
        id: "",
        label: "Niveau des diplômes délivrés",
        label_fr: "Niveau des diplômes délivrés",
        label_en: "Level of diplomas awarded",
        checked: true
    },
    {
        id: "master",
        label: "Master",
        label_fr: "Master",
        label_en: "Master",
        checked: true
    },
    {
        id: "ingenieur",
        label: "Diplôme d'ingénieur",
        label_fr: "Diplôme d'ingénieur",
        label_en: "Diploma of engineer",
        checked: true
    },
    {
        id: "licence",
        label: "Licence",
        label_fr: "Licence",
        label_en: "Licence",
        checked: true
    },
    {
        id: "bachelor",
        label: "Bachelor",
        label_fr: "Bachelor",
        label_en: "Bachelor",
        checked: true
    },
    {
        id: "mastere",
        label: "Mastère",
        label_fr: "Mastère",
        label_en: "MS or MSc",
        checked: true
    },
    {
        id: "PhD",
        label: "Doctorat",
        label_fr: "Doctorat",
        label_en: "PhD",
        checked: true
    }
]
