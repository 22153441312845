import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {getLabels} from '../constants/labels';
import {createTheme} from '@material-ui/core/styles';
import ActionModal from './ActionModal';

const useStyles = makeStyles({
    root: {
        height: '100%',
        minWidth: 275,
        cursor: 'pointer',
        borderRadius: 0
    },
    root2: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        '&:last-child': {
            padding: 0
        }
    },
    programContent: {
        height: '100%',
        width: '60px',
        whiteSpace: 'nowrap',
        position: 'relative'
    },
    program: {
        transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        whiteSpace: 'normal',
        textAlign: 'center',
    },
    content: {
        padding: '10px'
    },
    sectionTitle: {
        fontWeight: "bold",
        fontSize: 12
    },
    sectionContent: {
        fontSize: 12
    }

});

const theme = createTheme();

export default function ActionCard({action, language, programs, signed_user}) {
    const classes = useStyles();
    const labels = getLabels(language);
    const programColor = programs.find(p => p.originalId === action.programId);
    const backgroundColor = programColor?.color || '#fff';
    const programStyle = {
        background: backgroundColor,
        color: theme.palette.getContrastText(backgroundColor)
    };

    const programRef = React.createRef();
    const [programWidth, setProgramWidth] = useState(undefined);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setProgramWidth((programRef.current?.offsetHeight || 170) - 20)
        }
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    }, [programRef]);
    return (
        <Grid item xs={12} lg={6}>
            <Card className={classes.root} variant="outlined" onClick={() => setOpen(true)}>
                <CardContent className={classes.root2}>
                    <div ref={programRef} className={classes.programContent} style={programStyle}>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.program}
                            style={{width: programWidth}}>
                            {action.program}
                        </Typography>
                    </div>
                    <Grid container spacing={1} className={classes.content}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <b>{action.name}</b>
                            </Typography>
                        </Grid>
                        {}<Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography className={classes.sectionContent} component="span">
                                <span className={classes.sectionTitle}>{labels.applicationPeriod}:</span>
                                <br></br>
                                {action.application_period}
                            </Typography>
                            <Typography className={classes.sectionContent} component="span">
                                <span className={classes.sectionTitle}>{labels.validityPeriod}:</span>
                                <br></br>
                                {action.validation_period}
                            </Typography>
                        </Grid>{}
                        <Grid item xs={12}>
                            <Typography className={classes.sectionTitle}>
                                {labels.partners}
                            </Typography>
                            <Typography className={classes.sectionContent}>
                                {action.partners}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <ActionModal open={open}
                action={action}
                labels={labels}
                onClose={() => setOpen(false)} color={backgroundColor}
                signed_user={signed_user}
                programs={programs}
                language={language}
            />
        </Grid>
    );
}
