import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0e0096",
    },
    secondary: {
      main: "#009b52",
    },
    header: "#879bcd",
    disabled: "#e2e2e2",
    evenRow: "#dfebf7"
  },
  typography: {
    fontFamily: 'Marianne'
  },
});