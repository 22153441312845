import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, Popover} from '@material-ui/core';
import * as d3 from 'd3';
import {STRAIGHT_LINE, VERTICAL_LINK} from '../constants/linkTypes';
import {    
    DEFAULT_LINK_COLOR,
    INRIA_COLOR,
    MAROC_UNIVERSITY_ARC_COLOR,
    TEXT_COLOR,
    REGION_ARC_COLOR,
    FRANCE_COLOR,
    MAROC_COLOR,
    LEVEL0_ARC_COLOR,
    INDICATOR_COLOR
} from '../constants/colors';


const LEVEL3_SPACE = "space--3"
const LEVEL2_SPACE = "space--2"
const LEVEL1_SPACE = "space--1"
const LEVEL0_SPACE = "space--0"   
const RADIUS_SIZE = 300;
const EXTRA_ENTITY_NAME_SPACE = 20

const SPACE_DELTA_UP = 0.143
const SPACE_DELTA_DOWN = 0.008

const styles = theme => ({
  div: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "100%",
    verticalAlign: "top",
    overflow: "hidden",
    textAlign: "center"
  },
  svg: {
    display: "inline-block",
    position: "absolute",
    top: 0,
    left: 0
  },
  paperPopup: {
    position: "relative",
    textAlign: "center",
    display:"inline-block",
    padding: "5px",
    paddingTop: "7px",
    fontSize: "12px",    
    border: "0px",
    borderRadius: "8px"
  },
  popup: {
    pointerEvents: "none",
    positionDelta: 30
  }
});

const wheelStyles = {
  root: {
    viewBox: `-50 0 1380 1380`,
    transform: `translate(${(RADIUS_SIZE*2.25 - 50)},${(RADIUS_SIZE*2.25)})`,
    externalArcWidth: 50
  },
  highlighted: {
    fontSize: 30,
    fontWeight: 900,
    textColor: 'white',
    arcColor: INRIA_COLOR,
    innerRadius: RADIUS_SIZE + 65,
    arcLength: (Math.PI * 20)/180,
    logoWidth: 120
  },
  level0: { // Institutions (internal wheel)
    fontSize: 14,
    //fontWeight: 'bold',
    textColor: TEXT_COLOR,
    arcColor: LEVEL0_ARC_COLOR,
    innerRadius: RADIUS_SIZE + 65,
    arcWidth: 10,
    maxTextWidth: 14
  },
  level0_inria_team: {
    fontWeight: 400
  },
  level1: { // Maroc universities
    fontSize: 18,
    fontWeight: 'bold',
    textColor: 'white',
    dy: 33,
    arcColor: MAROC_UNIVERSITY_ARC_COLOR,
    innerRadius: RADIUS_SIZE + 192 + EXTRA_ENTITY_NAME_SPACE
  },
  level2: { // Regions
    fontSize: 20,
    fontWeight: 700,
    textColor: 'white',
    dy: 33,
    arcColor: REGION_ARC_COLOR,
    innerRadius: RADIUS_SIZE + 248 + EXTRA_ENTITY_NAME_SPACE
  },
  level3: { // Countries
    fontSize: 20,
    dy: 33,
    fontWeight: 700,
    textColor: 'white',
    innerRadius: RADIUS_SIZE + 303 + EXTRA_ENTITY_NAME_SPACE
  },
  links: {
    opacity: 0.25,
    strokeWidth: 2.5,
    defaultColor: DEFAULT_LINK_COLOR
  }
}

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

// Returns an array with the entity hierarchy ids
export function getLevels(entity) {
  if (entity.id && entity.id.toString().includes(".")) {
    return (entity.id || "").split(".");
  }
  else return [entity.id]
  
}

function isSpace(entity) {
  return entity?.id.startsWith("space--");
}

/**
 *  D3 visualization to display collaborations.
 *  It is a circle made of arcs with entities related through lines inside.
 */
class CollVis extends React.Component {

    /*
    selectedEntity: Only its links (and children's links) are visible by hovering or clicking.

    hoveredEntity: Entity label is hovered. Its complete name is visible in a popup. 
                  Implies selectedEntity=hoveredEntity if there is no clicked entity.

    clickedEntity (from props): Entity label (o some of its parents) is clicked. Implies clickedEntity=selectedEntity. 
    */ 

    state = {
      selectedEntity: "",
      hoveredEntity: "",
      popup_open: false,
      popup_name: "",
      popup_position: { top: 0, left: 0 }
    }    

    getLevelElements(entities) {
      // Displayed entities. Disgarding low level institutions like CMM
      const d_entities = entities.filter(en => this.isLevel(en, 3) || this.isLevel(en, 2) || this.isLevel(en, 1) || this.isLevel(en, 0))
      const entities_and_spaces = this.addSpaces(d_entities)
      //const inriachile = entities_and_spaces.find(e => e.name === "Inria Chile")
      const unified_entities = this.sortAndReverseEntities(entities_and_spaces);
      // Separate per level
      let levels = {
        "highlighted": [],
        "level0": [],
        "level1": [],
        "level2": [],
        "level3": []
      }
      const levels_array = [0, 1, 2, 3]
      for (const e in unified_entities) {
        const entity = unified_entities[e];
        for (let le in levels_array) {
          const l = levels_array[le]
          if (entity.id.toString().includes(`space--${l}`) || this.isLevel(entity, l)) {
            levels[`level${l}`].push(entity);
          }
        }
      }
      return levels;
    }

    addSpaces(d_entities) {
      // Adding spaces
      let entities_and_spaces = []
      let space_index = 0;
      let previous_entity = null;
      for (let e in d_entities) {
          const entity = d_entities[e];
          // New country -> adding country space
          if (this.isLevel(entity, 3) && previous_entity!==null) {
              entities_and_spaces.push({id: `${LEVEL3_SPACE}.${space_index}`, fr_name: ""});
          }
          // New region -> Adding region space
          if (this.isLevel(entity, 2)) {
            entities_and_spaces.push({id: `${LEVEL2_SPACE}.${space_index}`, fr_name: ""});
          }
          if (!this.isLevel(entity, 3) && previous_entity!==null && getLevels(previous_entity).length > getLevels(entity).length) {
            if (!this.isFromFrance(previous_entity)) {
              entities_and_spaces.push({id: `${LEVEL1_SPACE}.${space_index}`, fr_name: ""});
            }
              entities_and_spaces.push({id: `${LEVEL0_SPACE}.${space_index}`, fr_name: ""});
          }
          entities_and_spaces.push(entity);
          space_index++
          previous_entity = entity;
      }
      return entities_and_spaces;
    }

    sortAndReverseEntities(entities_and_spaces) {
      let middle_space_entity = null;
      var found = 0
      
      for (let e in entities_and_spaces) {
        const entity = entities_and_spaces[e];
       
        if (entity.id.toString().includes(LEVEL3_SPACE)) {
          found = found + 1
        }
        if (found === 1) {
          middle_space_entity = entity;
          break;
        }
      }
      // Reverse second half of the information
      const half_space_index = entities_and_spaces.findIndex(e => e?.id === middle_space_entity?.id);
      const first_half = entities_and_spaces.slice(0, half_space_index);  
      const second_half = entities_and_spaces.slice(half_space_index, entities_and_spaces.length).reverse();
      const unified_entities = first_half.concat(second_half).reverse();
      return unified_entities;
    }

    isInriaFrance(entity) {
      if (entity.sortKey) {
        const levels = entity.sortKey.split(".");
        if (levels.length >= 3 && levels[2] === "Inria") return true;
      }
      return false;
    }

    isFromFrance(entity) {
      return entity.id.startsWith("fr");
    }

    isLevel(entity, level) {
      switch (level) {
        case 0:
          if (this.isFromFrance(entity)) {
            return  entity.id.split(".").length === 3 && !isSpace(entity);
          }
          else {
            return  entity.id.split(".").length === 4 && !isSpace(entity);
          }
        case 1:
          if (this.isFromFrance(entity)) {
            return false;
          }
          else {
            return  entity.id.split(".").length === 3 && !isSpace(entity);
          }
        case 2:
          return entity.id.split(".").length === 2 && !isSpace(entity);
        case 3:
            return !entity.id.toString().includes(".") && !isSpace(entity);
        default:
          return false
      }
    }

    getHalfIndex(entities_and_spaces) {
      // Get half index of the wheel (to know where invert the labels)
      let half_index = null;
      let first_country_id = ""
      for (var i = 0; i < entities_and_spaces.length; i ++) {
          if (first_country_id === "" && !isSpace(entities_and_spaces[i])) {
            first_country_id = entities_and_spaces[i].id.split(".")[0]
          }
          else if (!entities_and_spaces[i].id.startsWith(first_country_id) && !isSpace(entities_and_spaces[i])){
            half_index = i - 1;
            break;
          }
      }
      return half_index;
    }

    getExternalArcAngle(category_entity, level0_elements) {
      const children = level0_elements.filter(e => {
        return e.id.startsWith(category_entity.id)
      })
      return {
        startAngle: children[0]?.angles.startAngle,
        endAngle: children[children.length - 1]?.angles.endAngle
      }    
    }

    // Returns main arc angles for an entity: start, middle and end one.
    getArcAngles(entity_index, entities_and_spaces) {
      const half_index = this.getHalfIndex(entities_and_spaces); // Entities and spaces LEVEL 0
      const reverse_half_index = entities_and_spaces.length - half_index - 1;
      const circle_half = Math.PI; 

      let arc_length_first_half = (circle_half - (SPACE_DELTA_UP + SPACE_DELTA_DOWN)) / (reverse_half_index);
      let arc_length_second_half = (circle_half - (SPACE_DELTA_UP + SPACE_DELTA_DOWN)) / (half_index+1);

      let rotation = 0
      let startAngle;
      let endAngle;

      if (entity_index <= half_index) {
        startAngle = SPACE_DELTA_UP + entity_index * arc_length_second_half + rotation;
        endAngle = startAngle + arc_length_second_half;
      }
      else {
        startAngle = circle_half + SPACE_DELTA_DOWN + (entity_index - half_index -1) * arc_length_first_half + rotation;
        endAngle = startAngle + arc_length_first_half;
      }
      const middleAngle = (startAngle + endAngle) / 2;
      return {startAngle, middleAngle, endAngle};
    }

    
    isHoveredEntity(entity) {
      const {hoveredEntity} = this.state;
      return (hoveredEntity !== "" && (entity?.id === hoveredEntity || entity?.id?.startsWith(hoveredEntity + '.')));
    }


    // Is entity selected, that means, the visualization is showing only that entity links
    isSelectedEntity(entity) {
      const {clickedEntity} = this.props;
      const {selectedEntity} = this.state;
      if (clickedEntity !== "") {
        return clickedEntity === entity?.id || entity?.id?.startsWith(clickedEntity + '.')
      }
      else {
        return selectedEntity !== "" && (entity?.id === selectedEntity || entity?.id?.startsWith(selectedEntity + '.'));
      }
    }

    isSelectedLink(link, formattedEntities = []) {      
      const entity1 = formattedEntities.find(entity=>entity.id===link.id1);
      const entity2 = formattedEntities.find(entity=>entity.id===link.id2);
      return this.isSelectedEntity(entity1) || this.isSelectedEntity(entity2)      
    }

    isClickedEntity(entity) {
      const {clickedEntity} = this.props;
      return (clickedEntity !== "" && (entity?.id === clickedEntity || entity?.id?.startsWith(clickedEntity + '.')));
    }

    handleMouseOver(event, entity) {
        const {clickedEntity} = this.props;
        const {hoveredEntity} =  this.state;
        if (hoveredEntity !== entity.id){
            // If there is a clicked entity, selectedEntity is clickedEntity 
            const selected_entity = clickedEntity === "" ? entity.id : clickedEntity 
            this.setState({
              selectedEntity: selected_entity,
              hoveredEntity: entity.id,
              popup_name: entity.fr_name,
              popup_open: true,
              popup_position: { top: event.clientY, left: event.clientX + styles().popup.positionDelta}
            });
        }
    }

    handleMouseOut(event) {
      const {selectedEntity, hoveredEntity} = this.state;
      const {clickedEntity} = this.props;
      const selected_entity = clickedEntity === "" ? "" : selectedEntity       
      if (hoveredEntity !== "") {
        this.setState({
          selectedEntity: selected_entity,
          hoveredEntity: "",
          popup_name: "",
          popup_open: false
        });
      }      
    }   

    getMaxTextLength(angles, radius) {
      const start = [
          Math.cos(angles.startAngle - Math.PI / 2) * (radius + 25), //x
          Math.sin(angles.startAngle - Math.PI / 2) * (radius + 25) //y
      ];
      const end = [
          Math.cos(angles.endAngle - Math.PI / 2) * (radius + 25), //x
          Math.sin(angles.endAngle - Math.PI / 2) * (radius + 25) //y
      ];
      const arc_len = Math.sqrt(Math.pow((end[0] - start[0]), 2) + Math.pow((end[1] - start[1]), 2));
      const arc_unit = 13;
      const max_text_length = Math.floor(Math.floor(arc_len)/arc_unit);
      return max_text_length;
    }

    drawExternalArcs(svg, level, level_elements) {
        const handleLabelClick = this.props.handleLabelClick;
        const elements = level_elements["level" + level];
        const level_0_elements = level_elements["level0"];
        const getAngles = (entity, level0elements) => this.getExternalArcAngle(entity, level0elements);
        const setArcColor = (entity) => {
          if (level === 3) {
            if (entity.id.startsWith("fr")) {
              return FRANCE_COLOR;
            }
            else {
              return MAROC_COLOR;
            }
          }
          else {
            return wheelStyles["level"+level].arcColor;
          }
        }
        // Arcs
        svg.append("g")
        .selectAll("g")
        .data(elements)
        .enter().append("g")
        .append("path")
        .attr("d", (d, i) => {
            const angles = getAngles(d, level_0_elements);
            return d3.arc()({ // Draw the path as an arc
              innerRadius: wheelStyles["level"+level].innerRadius,
              outerRadius: wheelStyles["level"+level].innerRadius + wheelStyles.root.externalArcWidth,
              startAngle: angles.startAngle,
              endAngle: angles.endAngle
            });
        })
        
        .style("fill", d => setArcColor(d)) // Paints the path(arc)
        .style("stroke", d => setArcColor(d)) // Paints the path(arc) border
        .each(function(d,i) { // To create a hidden path for labels
          //A regular expression that captures all in between the start of a string
          //(denoted by ^) and the first capital letter L
          var firstArcSection = /(^.+?)L/;
          //The [1] gives back the expression between the () (thus not the L as well)
          //which is exactly the arc statement
          var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
          //Replace all the comma's so that IE can handle it -_-
          //The g after the / is a modifier that "find all matches rather than
          //stopping after the first match"
          newArc = newArc.replace(/,/g , " ");
          //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
          //flip the end and start position
          const angles = getAngles(d, level_0_elements);
          const middleAngle = (angles.startAngle + angles.endAngle) / 2;
          if (middleAngle > 100 * Math.PI/180 && middleAngle < 270 * Math.PI/180) {
            let sweep_flag_middle = "0";
            if (newArc.includes("0 1 1 ")){
              sweep_flag_middle = "1";
            }
            //Everything between the capital M and first capital A
            var startLoc = /M(.*?)A/;
            //Everything between the capital A and 0 0 1
            var middleLoc = new RegExp('A(.*?)0 ' + sweep_flag_middle + ' 1');
            //Everything between the 0 0 1 and the end of the string (denoted by $)
            var endLoc = new RegExp('0 ' + sweep_flag_middle + ' 1 (.*?)$');
            //Flip the direction of the arc by switching the start and end point
            //and using a 0 (instead of 1) sweep flag
            var newStart = endLoc.exec( newArc )?.[1];
            var newEnd = startLoc.exec( newArc )?.[1];
            var middleSec = middleLoc.exec( newArc )?.[1];
            if (newStart !== undefined && newEnd !== undefined && middleSec !== undefined) {
                //Build up the new arc notation, set the sweep-flag to 0
                newArc = "M" + newStart + "A" + middleSec + "0 " + sweep_flag_middle + " 0 " + newEnd;
            }
          }
          //Create a new invisible arc that the text can flow along
          svg.append("path")
              .attr("id", "level" + level + "_text" + i)
              .attr("d", newArc)
              .style("fill", "none");
        });
        // labels
        svg.append("g")
        .selectAll("g")
        .data(elements)
        .enter()
        .append("text")
        .attr("dy", d => {
          const angles = getAngles(d, level_0_elements);
          const middleAngle = (angles.startAngle + angles.endAngle) / 2;
          // If labels are at the bottom part of the wheel
          if (middleAngle > 100 * Math.PI/180 && middleAngle < 270 * Math.PI/180) {
                return - wheelStyles["level"+level].dy + 15;
          }
          // If labels are at the upper part of the wheel
          return wheelStyles["level"+level].dy;
        })
        .on("mouseenter", (e, d) => {
          this.handleMouseOver(e, d)
        })
        .on("mouseleave", (e, d) => {
          this.handleMouseOut(e);
        })
        .on("mouseup", (e, d) => {
          handleLabelClick(e, d)
        })
        .style("cursor", d => {if(this.isHoveredEntity(d)) return "pointer";})
        .style("font-size", wheelStyles["level"+level].fontSize + "px")
        .style('font-weight', wheelStyles["level"+level].fontWeight)
        .style('fill', wheelStyles["level"+level].textColor)
        .append("textPath")
        .attr("xlink:href", function(d,i){return "#level" + level + "_text" + i;})
        .style("text-anchor","middle")
        .attr("startOffset", "50%")
        .text(d => {
            const angles = getAngles(d, level_0_elements);
            const max_text_length = this.getMaxTextLength(angles, wheelStyles["level"+level].innerRadius)
            if (level === 2) {
              return truncateString(
                d?.fr_name || "",
                max_text_length
              )
            }
            if (max_text_length < 2) {
              return d.fr_name.substring(0, 1);
            }
            if (d?.fr_name.length > max_text_length) {
              if (d.short_name.trim() === "") {
                return truncateString(
                  d?.fr_name || "",
                  max_text_length
                )
              }
              else if (d?.fr_name.length > max_text_length) {
                return truncateString(
                  d?.short_name || "",
                  max_text_length
                )
              } 
              else return d.short_name;
            }
            else return d?.fr_name;
        })
    }

    createLinks(svg, links, linktype, checked, TeachingAreas, level0_plus_inriachile) {
      svg.append("g")
        .selectAll("path")
        .data(links)
        .enter().append("path")
        .attr("d", link => {
              const internalRadius = wheelStyles.level0.innerRadius
              const entity1_index = level0_plus_inriachile.findIndex(entity=>entity.id===link.id1);
              const entity2_index = level0_plus_inriachile.findIndex(entity=>entity.id===link.id2);
              if (entity1_index === -1 || entity2_index === -1) return null;

              const entity1_angle = level0_plus_inriachile[entity1_index].angles.middleAngle;
              const entity2_angle = level0_plus_inriachile[entity2_index].angles.middleAngle;

              const source = [
                Math.cos(entity1_angle - Math.PI / 2) * internalRadius, //x
                Math.sin(entity1_angle - Math.PI / 2) * internalRadius  //y
              ];
              const target = [
                Math.cos(entity2_angle - Math.PI / 2) * internalRadius, //x
                Math.sin(entity2_angle - Math.PI / 2) * internalRadius  //y
              ];

              switch(linktype) {
                case STRAIGHT_LINE:
                  return d3.line()([source, target]);
                case VERTICAL_LINK:
                  return d3.linkVertical()({source, target});
                default: // ARC_LINE: This type is the one used
                  let dx = target[0] - source[0];
                  let dy = target[1] - source[1];
                  let dr = Math.sqrt(dx * dx + dy * dy); // Final constant to flatten the curve
                  const angleDiff = entity1_angle - entity2_angle;
                  // Determing the direction of the curve, according to the source/target position
                  if ((Math.abs(angleDiff) > Math.PI && angleDiff > 0) ||
                  (Math.abs(angleDiff) < Math.PI && angleDiff < 0)) {
                    return `M${target[0]},${target[1]}A${dr},${dr} 0 0,1 ${source[0]},${source[1]}`;
                  }
                  return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
              }
          })
        .attr("fill", "none")
        .attr("stroke-width", wheelStyles.links.strokeWidth)
        .attr("stroke-opacity", link => {
            const area = checked.find(a => a?.label_fr.toLowerCase().trim() === link?.type.toLowerCase().trim());
            if ((!this.isSelectedLink(link, level0_plus_inriachile) && (this.state.selectedEntity !== "" || this.props.clickedEntity !== "")) ||
                (checked && !area.checked)
               ) return 0;
            return wheelStyles.links.opacity;
        })
        .attr("stroke", link => {
            const defaultcolor = wheelStyles.links.defaultColor;
            if (!TeachingAreas || TeachingAreas.length === 0) return defaultcolor;
            const type = TeachingAreas.find(t=>t?.id_name.toLowerCase().trim() === link?.type.toLowerCase().trim())
            return type?.color || defaultcolor;
        })
    }

    drawIndicator(svg, level, start_angle, end_angle, text) {
      const arc_width = level !== 0 ? wheelStyles.root.externalArcWidth : (text[0].startsWith("◀") ? 121 : 177) + EXTRA_ENTITY_NAME_SPACE;
      svg.append("g")
      .append("path")
      .attr("d",
        d3.arc()({
        innerRadius: wheelStyles["level" + level].innerRadius,
        outerRadius: wheelStyles["level" + level].innerRadius + arc_width,
        startAngle: start_angle,
        endAngle: end_angle
      }))
      .style("fill", INDICATOR_COLOR)
      .style("stroke", INDICATOR_COLOR)
      .each(function(d,i) {
        var firstArcSection = /(^.+?)L/;
        var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
        newArc = newArc.replace(/,/g , " ");
        svg.append("g")
        .selectAll("path")
        .data(text)
        .enter()
        .append("path")
        .attr("id", t => {
          return "level" + level + "_indicator_" + t
        })
        .attr("d", newArc)
        .style("fill", "none");
      });
      svg.append("g")
      .selectAll("text")
      .data(text)
      .enter()
      .append("text")
      .attr("dy", (t, i) => {
        return level !== 0 ? 30 : (t.startsWith("◀") && text.length === 1 ? 63 : (t.endsWith("▶") && text.length === 1 ? 90 : (i===0 ? 50 : i===1 ? 63 : 77)))
      })
      .style("font-size", "12px")
      .style('fill', "white")
      .append("textPath")
      .attr("xlink:href", t => {
        return "#level" + level + "_indicator_" + t
      })
      .style("text-anchor","middle")
      .attr("startOffset", "50%")
      .text(t => t)
    }

    drawIndicators(svg) {
      const ind_start_angle_1 = SPACE_DELTA_DOWN*2 - SPACE_DELTA_UP
      const ind_end_angle_1 = - SPACE_DELTA_DOWN + 0.002
      const ind_start_angle_2 = SPACE_DELTA_DOWN - 0.002
      const ind_end_angle_2 = SPACE_DELTA_UP - SPACE_DELTA_DOWN*2

      this.drawIndicator(svg, 3, ind_start_angle_1, ind_end_angle_1, ["◀ Pays"])
      this.drawIndicator(svg, 3, ind_start_angle_2, ind_end_angle_2, ["Pays ▶"])
      this.drawIndicator(svg, 2, ind_start_angle_1, ind_end_angle_1, ["◀ Région"])
      this.drawIndicator(svg, 2, ind_start_angle_2, ind_end_angle_2, ["Région ▶"])
      this.drawIndicator(svg, 1, ind_start_angle_1, ind_end_angle_1, ["◀ Uni"])
      this.drawIndicator(svg, 0, ind_start_angle_2, ind_end_angle_2, ["Uni ▶"])
      this.drawIndicator(svg, 0, ind_start_angle_1, ind_end_angle_1, ["◀ Faculté", "ou", "Ecole"])
    }


    drawVisualization() {
        // Remove previous data
        d3.select("#div-svg").selectAll("svg").remove();

        const {entities, links, linktype, checked, handleLabelClick, TeachingAreas} = this.props;
        if (entities.length === 0) return;

        // Get levels elements
        const level_elements = this.getLevelElements(entities);

        const level_0_elements = level_elements["level0"];
        const existsHighlighted = level_elements["highlighted"][0];
        const level0_plus_inriachile = existsHighlighted ? level_0_elements.concat([existsHighlighted]) : level_0_elements;

        // Assigning angles
        for (let e in level0_plus_inriachile) {
          level0_plus_inriachile[e].angles = this.getArcAngles(e, level0_plus_inriachile);
        }

        // CREATE THE SVG CANVAS
        let svg = d3.select("#div-svg")
          .append("svg") // Adds a svg element          
          .attr("width", "85%")
          .attr("height", "85%")
          // Adding style attributes to scale the chart according to the container
          .attr("viewBox", wheelStyles.root.viewBox)
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("className", this.props.classes.svg)
          .append("g") // Adds a container inside the svg element
          .attr("transform", wheelStyles.root.transform)


        // CREATE level0 ARCS
        svg.append("g")
          .selectAll("g")
          .data(level_0_elements) // formattedEntities
          .enter()
          .append("path")
          .style("fill", wheelStyles.level0.arcColor) // Paints the path(arc)
          .style("stroke", wheelStyles.level0.arcColor) // Paints the path(arc) border
          .attr("stroke-opacity", entity => {
              return isSpace(entity) ? 0 : 1;
            })
          .attr("opacity", entity => {
              return isSpace(entity) ? 0 : 1;
          })
          .attr("d", (d, i) => {
              if (d.highlight) {
                return d3.arc()({ // Draw the path as an arc
                  innerRadius: wheelStyles.level0.innerRadius,
                  outerRadius: wheelStyles.level2.innerRadius + wheelStyles.root.externalArcWidth,
                  startAngle: d.angles.startAngle,
                  endAngle: d.angles.endAngle
                });
              }
              return d3.arc()({ // Draw the path as an arc
                innerRadius: wheelStyles.level0.innerRadius,
                outerRadius: wheelStyles.level0.innerRadius + wheelStyles.level0.arcWidth,
                startAngle: d.angles.startAngle,
                endAngle: d.angles.endAngle
              });
          });

        // RENDER LEVEL0 LABELS AROUND THE CIRCLE
        const maxTextWidth_level0 = (entity) => {
          if (entity.id.startsWith("fr")) {
            return wheelStyles.level0.maxTextWidth + 10;
          }
          else {
            return wheelStyles.level0.maxTextWidth;
          }
        }
        svg.append("g")
          .selectAll("g")
          .data(level_0_elements)
          .enter().append("g")          
          .append("text")
          .on("mouseenter", (e, d) => {
            this.handleMouseOver(e, d)
          })
          .text(d => d.highlight ? "" : truncateString(d.short_name || d.fr_name || "", maxTextWidth_level0(d)))
          .attr('transform', d => {
            // Factor to adjust label position
            const alpha = d.highlight ? 91.5 : (d.angles.middleAngle > Math.PI ? 90.5 : 89);
            return "rotate(" + (d.angles.middleAngle * 180 / Math.PI - alpha) + ")"
            + "translate(" + (wheelStyles.level0.innerRadius + 15) + ")"
            + (d.angles.middleAngle > Math.PI ? "rotate(180)" : ""); // Rotate half of labels, so they can be read
          })
          .attr('text-anchor', d =>
            Math.sin((d.angles.endAngle + d.angles.startAngle) / 2) > 0 ? 'start' : 'end'
          )          
          .style("stroke", d => {
            if(this.isClickedEntity(d)) return "black";
          })
          .on("mouseup", (e, d) => {
            handleLabelClick(e, d)
          })
          .style("text-decoration", d => {if(this.isSelectedEntity(d) || this.isHoveredEntity(d)) return "underline";})
          .style("cursor", d => {if(this.isHoveredEntity(d)) return "pointer";})
          .attr("font-weight", d => {
            if (this.isSelectedEntity(d) || this.isHoveredEntity(d))
              return "bold";
            else return wheelStyles.level0.fontWeight;
          })
          .attr("font-size", wheelStyles.level0.fontSize + "px")
          .on("mouseleave", (e, d) => {            
            this.handleMouseOut(e);
          })

        // CREATE MAROC UNIVERSITY ARCS (LEVEL 1)
        this.drawExternalArcs(svg, 1, level_elements);
        // CREATE REGION ARCS (LEVEL 2)
        this.drawExternalArcs(svg, 2, level_elements);
        // CREATE COUNTRY ARCS (LEVEL 3)
        this.drawExternalArcs(svg, 3, level_elements);

        // CREATE THE LINKS
        this.createLinks(svg, links ,linktype, checked, TeachingAreas, level0_plus_inriachile);

        // CREATE INDICATORS
        this.drawIndicators(svg);

    }




    componentDidMount() {
      this.drawVisualization();
    }

    componentDidUpdate(prevProps, prevState) {      
      if (prevProps.clickedEntity !== this.props.clickedEntity) {        
        this.setState({selectedEntity: this.props.clickedEntity})
      }
      if (prevProps !== this.props || prevState !== this.state) {
        this.drawVisualization();
      }        
    }

    render() {
      const {classes} = this.props;

      return <div id={"div-svg"} className={classes.div}>
          <Popover
            classes={{root: classes.popup, paper: classes.paperPopup}}
            open={this.state.popup_open}
            anchorReference="anchorPosition"
            anchorPosition={this.state.popup_position}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
          {this.state.popup_name}
          </Popover>
      </div>;
    }
}


CollVis.propTypes = {
    /** Entities (countries, companies, centers, teams) to be displayed in the visualization
     *
     * It is an array with elements like: {id: "chile", name: "Chile", category_title: true},
     * where 'category_title' indicates that the entity is a big category that has its own color.
 
     * Elements are like: {id: "chile.companies.smes", name: "SMEs"},
     * where the 'id' indicates the entity hierarchy until its category (ids separated by dots).
    */
    entities: PropTypes.arrayOf(Object).isRequired,
    /** Links between the entities
     * Each element in this array is like:
     * {id1: "inria-chile", id2: "inria-bordeaux.pleiade", type: "european-project"},
     * where 'id1' and 'id2' are the ids of the entities to be connected (there is no direction,
     * so it doesn't matter which of two goes first)
     * The 'type' attribute is the id of a collaboration type that determines the link color.
     */
    links: PropTypes.arrayOf(Object).isRequired,
    /* Link types and its colors */
    TeachingAreas: PropTypes.arrayOf(Object),
    /** SVG width */
    width: PropTypes.number,
    /** SVG height */
    height: PropTypes.number,
    /** Constant to define the shape of the links
     * 3 posible values: ARC_LINE (default), VERTICAL_LINK and STRAIGHT_LINE
     */
    linktype: PropTypes.string
}

export default withStyles(styles)(CollVis);
